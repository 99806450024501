import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { IReport } from "../interfaces";

interface IPastReportsListItemProps {
    entries: string[];
    onClick: any;
    report: IReport;
}

class PastReportsListItem extends Component<IPastReportsListItemProps> {
    public render() {
        return (
            <Row className="past-reports-list-item-row" onClick={this.onClick}>
                {this.renderEntries()}
            </Row>
        );
    }

    private genericColEntry = (key: string) => {
        return (
            <Col xs={1} key={key} className="past-reports-list-item-col">
                <div/>
            </Col>
        );
    }

    private renderEntries = () => {
        const entries = this.props.entries.map(this.renderEntry);
        const extraSpaces = 12 - (Math.floor(12 / this.props.entries.length) * this.props.entries.length);
        for (let i = 0; i < extraSpaces; i++) {
            if (i % 2) {
                entries.push(this.genericColEntry(`${this.props.entries.length + i}`));
            } else {
                entries.unshift(this.genericColEntry(`${this.props.entries.length + i}`));
            }
        }
        return entries;
    }

    private renderEntry = (entry: string, index: number) => {
        return (
            <Col key={index} xs={Math.floor(12 / this.props.entries.length)} className="past-reports-list-item-col">
                <div>
                    {entry}
                </div>
            </Col>
        );
    }

    private onClick = () => {
        this.props.onClick(this.props.report);
    }
}

export default PastReportsListItem;
