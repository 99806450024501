import dateFormat from "dateformat";
import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import Divider from "../components/Divider";
import { DateFormat, Timeout } from "../constants/constants";
import ChallengeOptions from "../containers/ChallengeOptions";
import CustomerServingOptions from "../containers/CustomerServingOptions";
import DescriptionField from "../containers/DescriptionField";
import IdOptions from "../containers/IdOptions";
import IsCustomerServedOptions from "../containers/IsCustomerServedOptions";
import { IReport } from "../interfaces";

import CloseIcon from "../images/Close.svg";

interface IViewReportProps {
    report: IReport;
    exit?: any;
    store?: string | null;
}

class ReportContainer extends Component<IViewReportProps, any> {

    private VIEW_REPORT_RENDER_ITEMS: any[] = [];

    constructor(props: any) {
        super(props);
        this.VIEW_REPORT_RENDER_ITEMS = [
            this.renderDescription,
            this.renderSubmittedBy,
            this.renderChallengeOptions,
            this.renderCustomerServingOptions,
            this.renderIdOptions,
            this.renderIsCustomerServedOptions,
            this.renderSubmit,
        ];
    }

    public render() {
        return (
            <div>
                <CSSTransition
                    in={true}
                    appear={true}
                    classNames={"app-transition-slide"}
                    timeout={Timeout}
                >
                    <div id="view-report-parent">
                        {this.VIEW_REPORT_RENDER_ITEMS.map(this.renderListItem)}
                    </div>
                </CSSTransition>
            </div>
        );
    }

    private renderListItem = (renderFunction: any, index: number) => {
        return (
            <div className="view-report-list-item" key={index}>
                {renderFunction()}
            </div>
        );
    }

    private renderCloseIcon = () => {
        if (this.props.exit) {
            return (
                <div id="report-view-close">
                    <img src={CloseIcon} onClick={this.props.exit} alt="Close" />
                </div>
            );
        }
        return null;
    }

    private renderDescription = () => {
        return (
            <div id="view-report-description">
                <div id="view-report-description-contents" className={this.props.exit ? "description-shift" : ""}>
                    <div id="view-report-id">
                        {`Report #${this.props.report.reportId}`}
                    </div>
                    <div>
                        {dateFormat(this.props.report.timeSubmitted, DateFormat)}
                    </div>
                </div>
                {this.renderCloseIcon()}
            </div>
        );
    }

    private renderSubmittedBy = () => {
        return (
            <div id="view-report-submitted-by">
                <div className="view-report-divider"><Divider /></div>
                <span id="view-report-submitted-by-bold">{"Submitted by: "}</span>
                <span>{this.props.report.userId + (this.props.store && this.props.store !== "" ? ", " + this.props.store : "")}</span>
            </div>
        );
    }

    private renderChallengeOptions = () => {
        if (!this.props.report.challengeType) { return null; }
        return (
            <div>
                <div className="view-report-divider"><Divider /></div>
                <ChallengeOptions
                    onOptionsSelected={null}
                    initialValue={this.props.report.challengeType}
                    disabled={true}
                />
            </div>
        );
    }

    private renderCustomerServingOptions = () => {
        if (!this.props.report.customerServing) { return null; }
        return (
            <div>
                <div className="view-report-divider"><Divider /></div>
                <CustomerServingOptions
                    onOptionsSelected={null}
                    initialValue={this.props.report.customerServing}
                    disabled={true}
                />
            </div>
        );
    }

    private renderIdOptions = () => {
        if (!this.props.report.idProduced) { return null; }
        return (
            <div>
                <div className="view-report-divider"><Divider /></div>
                <IdOptions
                    onOptionsSelected={null}
                    initialValues={this.props.report.idProduced}
                    disabled={true}
                    showBirthdate={false}
                    multiple={true}
                />
            </div>
        );
    }

    private renderIsCustomerServedOptions = () => {
        if (!this.props.report.isCustomerServed) { return null; }
        return (
            <div>
                <div className="view-report-divider"><Divider /></div>
                <IsCustomerServedOptions
                    onOptionsSelected={null}
                    initialValue={this.props.report.isCustomerServed}
                    disabled={true}
                />
            </div>
        );
    }

    private renderSubmit = () => {
        return (
            <div>
                <div className="view-report-divider"><Divider /></div>
                <div id="view-report-text-field">
                    <DescriptionField
                        fieldDisabled={true}
                        showButtons={false}
                        submitDisabled={true}
                        initialValue={this.props.report.description || " "}
                    />
                </div>
            </div>
        );
    }
}

export default ReportContainer;
