import { RequestMethod } from "../constants/constants";
import MsalAuthService from "./MsalAuthService";

class BaseService {

    protected ChallengeRefusalApiEndpoint: string;

    constructor() {
        this.ChallengeRefusalApiEndpoint = process.env.REACT_APP_CHALLENGE_REFUSAL_API_ENDPOINT!;

        if (!process.env.REACT_APP_CHALLENGE_REFUSAL_API_ENDPOINT) {
            throw Error("Missing API Endpoint");
        }
    }

    // Should only be called when a user is logged in
    public sendAuthenticatedRequest = async (requestMethod: RequestMethod, endpoint: string, body: object = {}, queryParams: any = {}): Promise<any> => {
        if (navigator.onLine === false) { return; }
        // User should be logged in to aquire tokens.
        // This method should silently aquire tokens for the user and set the access token in the base service
        await MsalAuthService.acquireTokens();
        // Get the set access token
        const accessToken = MsalAuthService.getAccessToken();

        let queryString: string = "";
        if (Object.keys(queryParams).length) {
            queryString = "?";
            Object.keys(queryParams).forEach((key: string) => {
                queryString += `${key}=${queryParams[key]}&`;
            });
            queryString.substring(0, queryString.length - 1);
        }

        const requestParams: any = {
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Trace": "true",
                "Token": accessToken,
            },
            method: requestMethod,
        };
        if (Object.keys(body).length) { requestParams.body = JSON.stringify(body); }
        const response = await fetch(`${this.ChallengeRefusalApiEndpoint}${endpoint}${queryString}`, requestParams);
        const data = await response.json();
        return data;
    }

    // Should only be called when a user is logged in
    // Does not call response.json() on the response body
    public sendAuthenticatedRequestRaw = async (requestMethod: RequestMethod, endpoint: string, body: object = {}, queryParams: any = {}): Promise<any> => {
        if (navigator.onLine === false) { return; }
        // User should be logged in to aquire tokens.
        // This method should silently aquire tokens for the user and set the access token in the base service
        await MsalAuthService.acquireTokens();
        // Get the set access token
        const accessToken = MsalAuthService.getAccessToken();

        let queryString: string = "";
        if (queryParams) {
            queryString = "?";
            Object.keys(queryParams).forEach((key: string) => {
                queryString += `${key}=${queryParams[key]}&`;
            });
            queryString.substring(0, queryString.length - 1);
        }

        const requestParams: any = {
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Trace": "true",
                "Token": accessToken,
            },
            method: requestMethod,
        };
        if (Object.keys(body).length) { requestParams.body = JSON.stringify(body); }
        const response = await fetch(`${this.ChallengeRefusalApiEndpoint}${endpoint}${queryString}`, requestParams);
        return response;
    }
}

export default new BaseService();
