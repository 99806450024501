// interface file, much like components, define interfaces that will be used more than once here

export interface IReport {
    reportId: number;
    timeSubmitted: string;
    challengeType?: ChallengeOptionsEnum;
    customerServing?: CustomerServingOptionsEnum;
    idProduced?: IdOptionsEnum[];
    isCustomerServed?: IsCustomerServedEnum;
    userId: string;
    description?: string;
    storeId?: string;
}

export interface IReportUnsubmitted {
    timeSubmitted: string;
    challengeType?: ChallengeOptionsEnum;
    customerServing?: CustomerServingOptionsEnum;
    idProduced?: IdOptionsEnum[];
    isCustomerServed?: IsCustomerServedEnum;
    userId: string;
    description?: string;
}

// Warning DO NOT change the text within these enums without understanding how the backend receives them first.
// The following enums are linked to the Challenge Refusal database. Any changes to the text MUST be changed in the database as well.
// To change the button text go change the text within the corresponding mapper object.
export enum ChallengeOptionsEnum {
    UNDER_AGE = "Looks Under 25",
    INTOXICATED = "Intoxicated",
    SECOND_PARTY = "Buying for Others",
    SEASONAL_NTR = "Seasonal/Nothing to Report",
}

export const ChallengeOptionsMapper: any = {
    [ChallengeOptionsEnum.UNDER_AGE]: "Looks Under 25",
    [ChallengeOptionsEnum.INTOXICATED]: "Intoxicated",
    [ChallengeOptionsEnum.SECOND_PARTY]: "Buying for Others",
    [ChallengeOptionsEnum.SEASONAL_NTR]: "Seasonal/Nothing to Report",
};

export enum CustomerServingOptionsEnum {
    ONE = "One",
    MULTIPLE = "Multiple",
}

export const CustomerServingOptionsMapper: any = {
    [CustomerServingOptionsEnum.ONE]: "One",
    [CustomerServingOptionsEnum.MULTIPLE]: "Multiple",
};

export enum IdOptionsEnum {
    NONE = "No ID Produced",
    ONT_DRIVERS_LICENSE = "ONT Driver License",
    BYID_CARD = "BYID Card",
    CDN_PASSPORT = "CDN Passport",
    CDN_CITIZENSHIP = "CDN Citizenship",
    CDN_ARMED_FORCES = "CDN Armed Forces",
    ONT_PHOTO_CARD = "ONT Photo Card",
    PERM_RES_CARD = "Perm. Res. Card",
    CERT_IND_STATUS = "Cert. Ind. Status",
    OTHER = "Other",
}
export const IdOptionsMapper: any = {
    [IdOptionsEnum.NONE]: "No ID Produced",
    [IdOptionsEnum.ONT_DRIVERS_LICENSE]: "ON Driver's License",
    [IdOptionsEnum.BYID_CARD]: "BYID Card",
    [IdOptionsEnum.CDN_PASSPORT]: "CDN Passport",
    [IdOptionsEnum.CDN_CITIZENSHIP]: "CDN Citizenship",
    [IdOptionsEnum.CDN_ARMED_FORCES]: "CDN Armed Forces",
    [IdOptionsEnum.ONT_PHOTO_CARD]: "ON Photo Card",
    [IdOptionsEnum.PERM_RES_CARD]: "PR Card",
    [IdOptionsEnum.CERT_IND_STATUS]: "CERT IND Status",
    [IdOptionsEnum.OTHER]: "Other",
};

export enum IsCustomerServedEnum {
    YES = "Yes",
    NO = "No",
}

export const IsCustomerServed: any = {
    [IsCustomerServedEnum.YES]: "Yes",
    [IsCustomerServedEnum.NO]: "No",
};

export interface IUser {
    id: string;
}

export enum HttpRequestMethods {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export interface IServiceResponse {
    success: boolean;
    message?: string;
}
