import React, { Component } from "react";

interface ITitleBodySmallProps {
    title: string;
    body: string;
}

class TitleBodySmall extends Component<ITitleBodySmallProps> {
    constructor(props: ITitleBodySmallProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <div>
                <div className="title-body-small-title">{this.props.title}</div>
                <div className="title-body-small-body">{this.props.body}</div>
            </div>
        );
    }
}

export default TitleBodySmall;
