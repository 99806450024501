import React, { Component } from "react";
import { Redirect } from "react-router";
import { RoutePaths } from "../constants/constants";
import Header from "../containers/Header";
import ReportContainer from "../containers/ReportContainerView";
import { SidebarItems } from "../containers/Sidebar";
import { IReport, IUser } from "../interfaces";
import ReportService from "../services/ReportService";
import AuthService from "../services/AuthService";

interface IViewReportStates {
    redirect?: string;
    report: IReport;
}

const VIEW_REPORT_SIDEBAR_ITEMS = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];

class ViewReport extends Component<any, IViewReportStates> {

    constructor(props: any) {
        super(props);

        const authUser: IUser | void = AuthService.getUser();
        if (!authUser) {
            this.state = {
                redirect: RoutePaths.LOGIN,
                report: {
                    reportId: -1,
                    timeSubmitted: "",
                    userId: "",
                },
            };
            return;
        }

        const param: string = this.props.match.params.id;

        try {
            const reportId: number = parseInt(param, 10);
            const report: IReport | undefined = ReportService.getReportById(reportId);
            if (!report) {
                this.state = {
                    redirect: RoutePaths.PAST_REPORTS,
                    report: {
                        reportId: -1,
                        timeSubmitted: "",
                        userId: "",
                    },
                };
            } else {
                this.state = {
                    report,
                };
            }
        } catch (error) {
            this.state = {
                redirect: RoutePaths.PAST_REPORTS,
                report: {
                    reportId: -1,
                    timeSubmitted: "",
                    userId: "",
                },
            };
        }
    }

    public render() {
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />); }
        return (
            <div>
                <div id="app-header">
                    <Header
                        backFunction={this.onBack}
                        sidebarItems={VIEW_REPORT_SIDEBAR_ITEMS}
                        path={RoutePaths.VIEW_REPORT}
                    />
                </div>
                <div id="app-header-filler" />
                <ReportContainer
                    report={this.state.report}
                />
            </div>
        );
    }

    private onBack = () => {
        this.setState({
            redirect: RoutePaths.PAST_REPORTS,
        });
    }
}

export default ViewReport;
