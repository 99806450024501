import React, { Component } from "react";

import MoreOptionsIcon from "../images/MoreOptions.svg";

interface IUserCardDropDownProps {
    onClick: any;
    children: any;
}

class UserCardDropDown extends Component<IUserCardDropDownProps> {
    public render() {
        return (
            <div className="user-card-drop-down-parent" onClick={this.handleClick}>
                <img src={MoreOptionsIcon} alt={"More Options"}>
                    {this.props.children}
                </img>
            </div>
        );
    }

    private handleClick = (e: any) => {
        e.preventDefault();

        this.props.onClick(e);
    }
}

export default UserCardDropDown;
