import store from "store";
import { RequestMethod } from "../constants/constants";
import { IReport, IReportUnsubmitted } from "../interfaces";
import ReportsApiService from "./api/ReportsApiService";
import BaseService from "./BaseService";

const STORAGE_SERVICE_INDEX = "reports";
const STORAGE_SERVICE_TOSEND_INDEX = "reportsToPost";

class StorageService {

  constructor() {
    if (!store.get(STORAGE_SERVICE_TOSEND_INDEX)) {
      store.set(STORAGE_SERVICE_TOSEND_INDEX, []);
    }
  }

  public sendSavedReports = async () => {
    const reportsToSend = store.get(STORAGE_SERVICE_TOSEND_INDEX);
    await Promise.all(reportsToSend.map((report: IReport) => this.saveReport(report)));
    store.set(STORAGE_SERVICE_TOSEND_INDEX, []);
  }

  public saveReport = async (report: IReportUnsubmitted) => {

    if (!navigator.onLine) {
      const reportsToSend = store.get(STORAGE_SERVICE_TOSEND_INDEX);
      reportsToSend.push(report);
      store.set(STORAGE_SERVICE_TOSEND_INDEX, reportsToSend);
      return;
    }
    const reportResponse = await ReportsApiService.createReport(report);
    const reportArray: IReport[] = this.getLocalReports();
    reportArray.push(reportResponse);
    this.setReports(reportArray);
  }

  public getMostRecentReport(): IReport | undefined {
    const reportArray: IReport[] = this.getLocalReports();
    if (reportArray === []) { return undefined; }
    return reportArray[reportArray.length - 1];
  }

  public replaceMostRecentReport(report: IReport) {
    const reportArray: IReport[] = this.getLocalReports();
    if (reportArray === []) { return; }
    reportArray[reportArray.length - 1] = report;
    this.setReports(reportArray);
  }

  public getReportById(id: number): IReport | undefined {
    const reports: IReport[] = this.getLocalReports();
    return reports.find((report: IReport) => report.reportId === id);
  }

  public getFollowingReport(id: number): IReport | undefined {
    const reports: IReport[] = this.getLocalReports();
    const reportIndex: number | undefined = reports.findIndex((report: IReport) => report.reportId === id);
    if (typeof reportIndex === "undefined" || reportIndex === reports.length - 1) {
      return undefined;
    }
    return reports[reportIndex + 1];
  }

  public getPreviousReport(id: number): IReport | undefined {
    const reports: IReport[] = this.getLocalReports();
    const reportIndex: number | undefined = reports.findIndex((report: IReport) => report.reportId === id);
    if (typeof (reportIndex) === "undefined" || reportIndex === 0) {
      return undefined;
    }
    return reports[reportIndex - 1];
  }

  public getAllReports = async (): Promise<IReport[]> => {
    const dateFrom = new Date(new Date().setFullYear(2000)).toISOString();
    const dateTo = new Date().toISOString();
    const query = {
      dateFrom,
      dateTo,
    };
    const reportsResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.GET, "/cr", {}, query);
    const reports = reportsResponse.body;
    reports.map((report: any) => {
      report.reportId = parseInt(report.reportId, 10);
      return report;
    });
    reports.sort((a: IReport, b: IReport) => {
      return (a.timeSubmitted < b.timeSubmitted) ? -1 : ((a.timeSubmitted > b.timeSubmitted) ? 1 : 0);
    });
    this.setReports(reports);
    return reports;
  }

  public getAllReportsAdmin = async (storeIds: string[], startDate?: Date, endDate?: Date): Promise<any> => {
    const dateFrom = startDate || new Date(new Date().setFullYear(2000)).toISOString();
    const dateTo = endDate || new Date().toISOString();
    const query = {
      dateFrom,
      dateTo,
    };
    const reportsResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.GET, "/cr", {}, query);
    const reports = reportsResponse.body;
    const isIncluded = (array: string[], item: string) => {
        let result = false;
        array.forEach((element) => {
            if (element === item) {
                result = true;
            }
        });
        return result;
    };
    const filteredReports = reports.filter((report: any) => isIncluded(storeIds, report.storeId)).map((report: any) => {
      report.reportId = parseInt(report.reportId, 10);
      return report;
    }).sort((a: IReport, b: IReport) => {
      return (a.timeSubmitted < b.timeSubmitted) ? -1 : ((a.timeSubmitted > b.timeSubmitted) ? 1 : 0);
    });
    this.setReports(filteredReports);
    return filteredReports;
  }

  public getReportsByDate(startDate: Date, endDate: Date, reports?: IReport[]): IReport[] {
    if (!reports) {
      const allReports: IReport[] = this.getLocalReports().reverse();
      return allReports.filter((report: IReport) => {
        const compareDate: Date = new Date(report.timeSubmitted);
        return (compareDate >= startDate && compareDate <= endDate);
      });
    } else {
      return reports.filter((report: IReport) => {
        const compareDate: Date = new Date(report.timeSubmitted);
        return (compareDate >= startDate && compareDate <= endDate);
      });
    }
  }

  public getReportsCSV = async (dateFrom: string, dateTo: string) => {
    const reportsResponse = await ReportsApiService.getCSVData(dateFrom, dateTo);
    return reportsResponse;
  }

  public getReportsCsvWithStoreId = async (dateFrom: string, dateTo: string, storesSelected: string[]) => {
    const reportsResponse = await ReportsApiService.getCSVDataByStore(dateFrom, dateTo, storesSelected);
    return reportsResponse;
  }

  public sendCSVDataEmailWithStoreId = async (dateFrom: string, dateTo: string, storesSelected: string[]) => {
    const reportsResponse = await ReportsApiService.sendCSVDataEmailByStore(dateFrom, dateTo, storesSelected);
    return reportsResponse;
  }

  public sendCSVDataEmail = async (dateFrom: string, dateTo: string) => {
    const reportsResponse = await ReportsApiService.sendCSVDataEmail(dateFrom, dateTo);
    return reportsResponse;
  }

  public clearReports = () => {
    this.setReports([]);
  }

  public getLocalReports(): IReport[] {
    return store.get(STORAGE_SERVICE_INDEX, [])
    .filter((report: IReport) => {
      return Boolean(report);
    });

  }

  private setReports(reports: IReport[]): void {
    store.set(STORAGE_SERVICE_INDEX, reports);
  }

}

export default new StorageService();
