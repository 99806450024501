import React, { Component } from "react";

interface IPastReportsTableHeader {
    date: string;
}

class PastReportsTableHeader extends Component<IPastReportsTableHeader> {
    public render() {
        return (
            <div className="past-reports-table-header-parent">
                {this.props.date}
            </div>
        );
    }
}

export default PastReportsTableHeader;
