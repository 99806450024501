import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import GenericButton from "../components/GenericButton";
import { ButtonClassNames, RoutePaths } from "../constants/constants";
import { IUser } from "../interfaces";
import AuthService from "../services/AuthService";
import MsalAuthService from "../services/MsalAuthService";
import UsersService from "../services/UsersService";

interface ILoginState {
    redirect?: RoutePaths;
    loading: boolean;
}

class Login extends Component<any, ILoginState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    public componentDidMount = async () => {

        const user: IUser | void = AuthService.getUser();
        if (!user) { return; }

        await this.setState({
            loading: true,
        });

        const stores = await UsersService.setRoles();

        if (user && stores.length) {
            this.setState({
                redirect: RoutePaths.EXPORT_REPORTS_ADMIN,
            });
        } else if (user) {
            this.setState({
                redirect: RoutePaths.USERS,
            });
        }

        await this.setState({
            loading: false,
        });
    }

    public render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
        }
        return (
            <Container id="login-container">
                <div id="login-contents">
                    <div id="login-title">
                        <h1>Challenge</h1>
                        <h1>&</h1>
                        <h1 id="login-refusal">Refusal</h1>
                    </div>
                    <div id="login-button-parent">
                        <div id="login-button">
                            <GenericButton
                                onClick={this.login}
                                disabled={this.state.loading}
                                className={ButtonClassNames.SELECTED}
                                value="Login"
                            />
                            <div id="login-text">
                                Please login with your LCBO Email
                        </div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }

    private login = () => {
        const user = AuthService.getUser();
        if (!user) {
            MsalAuthService.authenticate();
        } else {
            this.setState({
                redirect: RoutePaths.USERS,
            });
        }
    }
}

export default Login;
