import dateFormat from "dateformat";
import React, { Component } from "react";
import SummaryNavBar, { SummaryNavBarTypes } from "../components/SummaryNavBar";
import { ChallengeOptionsEnum, IReport, IsCustomerServedEnum } from "../interfaces";
import ReportService from "../services/ReportService";

import SmallArrowLeftIcon from "../images/SmallArrowLeft.svg";
import SmallArrowRightIcon from "../images/SmallArrowRight.svg";

enum SummaryTableScopes {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
}

interface ISummaryTableProps {
    filterUserId?: string;
    allReports: IReport[];
}

interface ISummaryTableStates {
    reports: IReport[];
    startDate: Date;
    endDate: Date;
    scope: SummaryTableScopes;
}

interface ISummaryTableEntry {
    data: number;
    title: string;
}

class SummaryTable extends Component<ISummaryTableProps, ISummaryTableStates> {
    constructor(props: any) {
        super(props);

        const startDate: Date = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        const endDate: Date = new Date(startDate);
        endDate.setDate(startDate.getDate() + 1);

        const localReports: IReport[] = this.props.allReports;
        const filteredReports: IReport[] = this.filterReports(startDate, endDate, localReports, props.filterUserId);

        this.state = {
            endDate,
            reports: filteredReports,
            scope: SummaryTableScopes.DAY,
            startDate,
        };
    }

    public componentDidUpdate = (prevProps: ISummaryTableProps) => {
        if (prevProps.filterUserId !== this.props.filterUserId) {
            this.setState({
                reports: this.filterReports(this.state.startDate, this.state.endDate, this.props.allReports, this.props.filterUserId),
            });
        }

        if (this.compareReports(this.props.allReports, prevProps.allReports) === false) {
            const reports: IReport[] = this.filterReports(this.state.startDate, this.state.endDate, this.props.allReports, this.props.filterUserId);
            this.setState({
                reports,
            });
        }
    }

    public render() {
        return (
            <div id="summary-table-parent">
                <SummaryNavBar
                    selected={this.state.scope}
                    items={[SummaryTableScopes.DAY, SummaryTableScopes.WEEK, SummaryTableScopes.MONTH]}
                    onClickHandler={this.onNavbarClick}
                    type={SummaryNavBarTypes.SMALL}
                />
                <div id="summary-table-date-picker">
                    <div id="summary-table-date-arrow-wrapper">
                        <div className="summary-table-date-arrow" onClick={this.goBack}><img src={SmallArrowLeftIcon} alt="<" /></div>
                    </div>
                    <div id="summary-table-date">
                        {this.getDateRange()}
                    </div>
                    <div id="summary-table-date-arrow-wrapper">
                        {this.isMostRecent() ? null : <div className="summary-table-date-arrow" onClick={this.goForward}><img src={SmallArrowRightIcon} alt=">" /></div>}
                    </div>
                </div>
                {this.renderStats()}
            </div >
        );
    }

    private renderStats = () => {
        const AGE_REFUSED: number = this.state.reports.filter((report: IReport) => {
            return (
                report.challengeType && report.challengeType === ChallengeOptionsEnum.UNDER_AGE &&
                report.isCustomerServed && report.isCustomerServed === IsCustomerServedEnum.NO
            );
        }).length;
        const AGE_SERVED: number = this.state.reports.filter((report: IReport) => {
            return (
                report.challengeType && report.challengeType === ChallengeOptionsEnum.UNDER_AGE &&
                report.isCustomerServed && report.isCustomerServed === IsCustomerServedEnum.YES
            );
        }).length;
        const INTOXICATED: number = this.state.reports.filter((report: IReport) => {
            return (
                report.challengeType && report.challengeType === ChallengeOptionsEnum.INTOXICATED
            );
        }).length;
        const OTHER_REFUSED: number = this.state.reports.filter((report: IReport) => {
            return (
                report.challengeType && report.challengeType === ChallengeOptionsEnum.SECOND_PARTY
            );
        }).length;
        const OTHER_SERVED: number = 0;
        const SEASONAL: number = this.state.reports.filter((report: IReport) => {
            return (
                report.challengeType && report.challengeType === ChallengeOptionsEnum.SEASONAL_NTR
            );
        }).length;

        const stats: ISummaryTableEntry[] = [
            {
                data: AGE_REFUSED,
                title: "Age Challenge Refused",
            },
            {
                data: AGE_SERVED,
                title: "Age Challenge Served",
            },
            {
                data: INTOXICATED,
                title: "Intoxicated",
            },
            {
                data: OTHER_REFUSED,
                title: "Other Challenge Refused",
            },
            {
                data: OTHER_SERVED,
                title: "Other Challenge Served",
            },
            {
                data: SEASONAL,
                title: "Seasonal/Nothing to Report",
            },
        ];
        return (
            <div>
                <table id="summary-table-stats">
                    <tbody>
                        {stats.map(this.renderTableRow)}
                        <tr className="summary-table-entry">
                            <td className="summary-table-entry-title summary-table-entry-total">
                                Total
                        </td>
                            <td className="summary-table-entry-data summary-table-entry-total">
                                {this.state.reports.length}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    private renderTableRow = (entry: ISummaryTableEntry, index: number) => {
        return (
            <tr className="summary-table-entry" key={index}>
                <td className="summary-table-entry-title">
                    {entry.title}
                </td>
                <td className="summary-table-entry-data">
                    {entry.data}
                </td>
            </tr>
        );
    }

    private filterReports = (startDate: Date, endDate: Date, reports: IReport[], filterUserId?: string): IReport[] => {
        const datedReports: IReport[] = ReportService.getReportsByDate(startDate, endDate, reports);
        return filterUserId ? datedReports.filter((report: IReport) => report.userId === filterUserId) : datedReports;
    }

    private onNavbarClick = (item: SummaryTableScopes) => {
        const endDate: Date = new Date();
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
        endDate.setMilliseconds(0);

        const startDate = new Date(endDate);

        if (item === SummaryTableScopes.DAY) {
            startDate.setDate(startDate.getDate() - 1);
        } else if (item === SummaryTableScopes.WEEK) {
            startDate.setDate(startDate.getDate() - startDate.getDay());
            endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
        } else if (item === SummaryTableScopes.MONTH) {
            startDate.setDate(1);
        }

        this.setState({
            endDate,
            reports: this.filterReports(startDate, endDate, this.props.allReports, this.props.filterUserId),
            scope: item,
            startDate,
        });
    }

    private getDateRange = (): string => {
        const dateFormatString = "mmm d";
        if (this.state.scope === SummaryTableScopes.DAY) {
            return dateFormat(this.state.startDate, dateFormatString);
        } else if (this.state.scope === SummaryTableScopes.WEEK) {
            return `${dateFormat(this.state.startDate, dateFormatString)} - ${dateFormat(this.state.endDate, dateFormatString)}`;
        } else {
            return dateFormat(this.state.startDate, "mmm yyyy");
        }
    }

    private isMostRecent = (): boolean => {
        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);
        return this.state.endDate > today;
    }

    private goBack = () => {
        const startDate: Date = this.state.startDate;
        let endDate: Date = this.state.endDate;

        if (this.state.scope === SummaryTableScopes.DAY) {
            startDate.setDate(startDate.getDate() - 1);
            endDate.setDate(endDate.getDate() - 1);
        } else if (this.state.scope === SummaryTableScopes.WEEK) {
            endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() - 1);
            startDate.setDate(startDate.getDate() - 7);
        } else if (this.state.scope === SummaryTableScopes.MONTH) {
            endDate.setDate(startDate.getDate() - 1);
            startDate.setMonth(startDate.getMonth() - 1);
        }

        this.setState({
            endDate,
            reports: this.filterReports(startDate, endDate, this.props.allReports, this.props.filterUserId),
            startDate,
        });
    }

    private goForward = () => {
        let startDate: Date = this.state.startDate;
        const endDate: Date = this.state.endDate;

        if (this.state.scope === SummaryTableScopes.DAY) {
            startDate.setDate(startDate.getDate() + 1);
            endDate.setDate(endDate.getDate() + 1);
        } else if (this.state.scope === SummaryTableScopes.WEEK) {
            startDate = new Date(endDate);
            startDate.setDate(startDate.getDate() + 1);
            endDate.setDate(endDate.getDate() + 7);
        } else if (this.state.scope === SummaryTableScopes.MONTH) {
            startDate.setMonth(endDate.getMonth() + 1);
            endDate.setMonth(endDate.getMonth() + 1);
        }

        this.setState({
            endDate,
            reports: this.filterReports(startDate, endDate, this.props.allReports, this.props.filterUserId),
            startDate,
        });
    }

    private compareReports = (reports1: IReport[], reports2: IReport[]): boolean => {
        if (reports1.length !== reports2.length) { return false; }

        reports1.forEach((report1: IReport, index: number) => {
            if (report1.reportId !== reports2[index].reportId) {
                return false;
            }
        });
        return true;
    }
}

export default SummaryTable;
