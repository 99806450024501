import React, { Component } from "react";

interface IFormTitleProps {
    text: string;
}

class FormTitle extends Component<IFormTitleProps> {
    public render() {
        return (
            <p className="buttongroup-title"> {this.props.text} </p>
        );
    }
}

export default FormTitle;
