import React, { Component } from "react";
import ButtonGroup from "../components/ButtonGroup";
import { ButtonGroupColumns, ButtonGroupTitles } from "../constants/constants";
import { CustomerServingOptionsEnum, CustomerServingOptionsMapper } from "../interfaces";

const CUSTOMER_SERVING_OPTIONS: string[] = Object.values(CustomerServingOptionsEnum);

interface ICustomerServingOptions {
    onOptionsSelected: any;
    initialValue?: CustomerServingOptionsEnum;
    disabled?: boolean;
}

class CustomerServingOptions extends Component<ICustomerServingOptions> {

    public render() {
        return (
            <div id="customer-serving-options-parent">
                <ButtonGroup
                    title={ButtonGroupTitles.CUSTOMER_SERVING_OPTIONS}
                    options={CUSTOMER_SERVING_OPTIONS}
                    onOptionSelectHandler={this.onSelected}
                    nColumns={ButtonGroupColumns.ONE}
                    initialSelectedValues={this.props.initialValue ? [this.props.initialValue] : undefined}
                    disabled={this.props.disabled || false}
                    buttonWidth={"144px"}
                    optionMapper={CustomerServingOptionsMapper}
                />
            </div>
        );
    }

    private onSelected = (title: ButtonGroupTitles, value: CustomerServingOptionsEnum[]) => {
        this.props.onOptionsSelected(title, value[0]);
    }
}

export default CustomerServingOptions;
