import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import FormTitle from "../components/FormTitle";
import GenericButton from "../components/GenericButton";
import LargeInput from "../components/LargeInput";
import { ButtonClassNames } from "../constants/constants";

interface IDescriptionFieldProps {
    onChange?: any;
    onSubmit?: any;
    onCancel?: any;
    fieldDisabled: boolean;
    submitDisabled: boolean;
    showButtons: boolean;
    initialValue?: string;
}

class DescriptionField extends Component<IDescriptionFieldProps> {
    public render() {
        return (
            <div id="description-field-parent">
                <FormTitle text="Descriptive Info" />
                <LargeInput
                    value={this.props.initialValue}
                    disabled={this.props.fieldDisabled}
                    onChange={this.props.onChange}
                    hint={"Products and physical features (height, hair colour, tattoos, etc.)"}
                />
                {this.renderButtons()}
            </div>
        );
    }

    private renderButtons = () => {
        if (this.props.showButtons === true) {
            return (
                <Row id="description-field-submit-row">
                    <Col>
                        <GenericButton
                            onClick={this.props.onCancel}
                            className={ButtonClassNames.RED}
                            disabled={false}
                            value={"CANCEL"}
                        />
                    </Col>
                    <Col>
                        <GenericButton
                            onClick={this.props.onSubmit}
                            className={ButtonClassNames.GREEN}
                            disabled={this.props.submitDisabled}
                            value={"SUBMIT"}
                        />
                    </Col>
                </Row>
            );
        }
        return null;
    }
}

export default DescriptionField;
