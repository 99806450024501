import React, { Component } from "react";
import { Redirect } from "react-router";
import { Element, scroller } from "react-scroll";

import { Collapse } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Divider from "../components/Divider";
import { ButtonGroupTitles, RoutePaths, Timeout } from "../constants/constants";
import ChallengeOptions from "../containers/ChallengeOptions";
import CustomerServingOptions from "../containers/CustomerServingOptions";
import Header from "../containers/Header";
import IdOptions from "../containers/IdOptions";
import IsCustomerServedOptions from "../containers/IsCustomerServedOptions";
import { SidebarItems } from "../containers/Sidebar";
import { ChallengeOptionsEnum, CustomerServingOptionsEnum, IdOptionsEnum, IReportUnsubmitted, IsCustomerServedEnum, IUser } from "../interfaces";

import DescriptionField from "../containers/DescriptionField";
import AuthService from "../services/AuthService";
import ReportService from "../services/ReportService";
import UsersService from "../services/UsersService";

const CHALLENGE_SIDEBAR_ITEMS: SidebarItems[] = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];

enum ChallengeElements {
    CHALLENGE_OPTIONS = "challenge-options",
    CUSTOMER_SERVING_OPTIONS = "customer-serving-options",
    ID_OPTIONS = "id-options",
    IS_CUSTOMER_SERVED_OPTIONS = "is-customer-served",
    DESCRIPTION_FIELD = "description-field",
    SUBMIT = "submit",
}

interface IChallengeStates {
    renderItems: any[];
    allItems: any[];
    redirectPath?: string;
    report?: IReportUnsubmitted;
    scrollTo?: ChallengeElements;
    backFunction?: any;
    sidebarFunction?: any;
    user: IUser;
}

class Challenge extends Component<any, IChallengeStates> {

    private DEFAULT_RENDER: any[];
    private UNDERAGE_RENDER: any[];
    private INTOXICATED_OTHERS_RENDER: any[];
    private SEASONAL_NTR_RENDER: any[];

    constructor(props: any) {
        super(props);

        this.DEFAULT_RENDER = [
            this.renderChallengeOptions,
            this.renderCustomerServingOptions,
        ];

        this.UNDERAGE_RENDER = [
            ...this.DEFAULT_RENDER,
            this.renderIdOptions,
            this.renderIsCustomerServedOptions,
            this.renderSubmit,
        ];

        this.INTOXICATED_OTHERS_RENDER = [
            ...this.DEFAULT_RENDER,
            this.renderSubmit,
        ];

        this.SEASONAL_NTR_RENDER = [
            this.renderChallengeOptions,
            this.renderSubmit,
        ];

        const userId: string = UsersService.getCurrentUserId();
        const authUser: IUser | void = AuthService.getUser();
        if (!authUser || userId === "") {
            this.state = {
                allItems: [],
                redirectPath: RoutePaths.USERS,
                renderItems: [],
                user: { id: "" },
            };
            return;
        }

        this.state = {
            allItems: [
                this.renderChallengeOptions,
                this.renderCustomerServingOptions,
                this.renderIdOptions,
                this.renderIsCustomerServedOptions,
                this.renderSubmit,
            ],
            renderItems: [this.renderChallengeOptions],
            user: {
                id: userId,
            },
        };
    }

    public render() {
        if (this.state.redirectPath) {
            return (<Redirect to={this.state.redirectPath} />);
        } else {
            return (
                <div>
                    <div id="app-header">
                        <Header
                            backFunction={this.state.backFunction}
                            sidebarItems={CHALLENGE_SIDEBAR_ITEMS}
                            path={RoutePaths.CHALLENGE}
                            onSwitchUser={this.onSwitchUser}
                            onSamePageClickHandler={this.resetForm}
                        />
                    </div>
                    <div id="app-header-filler" />
                    <CSSTransition
                        in={true}
                        appear={true}
                        classNames={"app-transition-slide-back"}
                        timeout={Timeout}
                    >
                        <div id="challenge-parent">
                            {this.state.allItems.map(this.renderListItem)}
                        </div>
                    </CSSTransition>
                </div>
            );
        }
    }

    public componentDidUpdate = () => {
        this.scrollTo();
    }

    private scrollTo = () => {
        if (this.state.scrollTo) {
            scroller.scrollTo(this.state.scrollTo, {
                delay: 0,
                duration: 300,
                offset: -78,
                smooth: "linear",
            });
        }
    }

    private renderListItem = (renderFunction: any, index: number) => {
        return (
            <Collapse
                in={this.state.renderItems.includes(renderFunction)}
                onEntering={this.scrollTo}
                key={index}
            >
                <div key={index}>
                    {(index !== 0) ? (<Divider />) : null}
                    <div className="challenge-list-item">
                        {renderFunction()}
                    </div>
                </div>
            </Collapse>
        );
    }

    private renderChallengeOptions = () => {
        return (
            <Element name={ChallengeElements.CHALLENGE_OPTIONS}>
                <ChallengeOptions
                    onOptionsSelected={this.onChallengeOptionsSelected}
                    initialValue={this.state.report ? this.state.report.challengeType : undefined}
                />
            </Element>
        );
    }

    private renderCustomerServingOptions = () => {
        return (
            <Element name={ChallengeElements.CUSTOMER_SERVING_OPTIONS}>
                <CustomerServingOptions
                    onOptionsSelected={this.onCustomerServingOptionsSelected}
                    initialValue={this.state.report ? this.state.report.customerServing : undefined}
                />
            </Element>
        );
    }

    private renderIdOptions = () => {
        return (
            <Element name={ChallengeElements.ID_OPTIONS}>
                <IdOptions
                    onOptionsSelected={this.onIdOptionsSelected}
                    initialValues={this.state.report ? this.state.report.idProduced : undefined}
                    showBirthdate={true}
                    multiple={this.state.report && this.state.report.customerServing ? this.state.report.customerServing === CustomerServingOptionsEnum.MULTIPLE : false}
                />
            </Element>
        );
    }

    private renderIsCustomerServedOptions = () => {
        return (
            <Element name={ChallengeElements.IS_CUSTOMER_SERVED_OPTIONS}>
                <IsCustomerServedOptions
                    onOptionsSelected={this.onIsCustomerServedSelected}
                    initialValue={this.state.report ? this.state.report.isCustomerServed : undefined}
                />
            </Element>
        );
    }

    private renderSubmit = () => {
        return (
            <Element name={ChallengeElements.SUBMIT}>
                <div id="challenge-description-field">
                    <DescriptionField
                        fieldDisabled={false}
                        showButtons={true}
                        onChange={this.onDescriptionFieldChange}
                        onSubmit={this.onSubmit}
                        onCancel={this.resetForm}
                        submitDisabled={!this.isFormFilled()}
                    />
                </div>
            </Element>
        );
    }

    private onReportCompleted = (report: IReportUnsubmitted | undefined) => {
        if (report) {
            report.timeSubmitted = new Date().toISOString();
            ReportService.saveReport(report);
            this.setState({
                redirectPath: RoutePaths.SUBMITTED,
                scrollTo: undefined,
            });
        }
    }

    private onChallengeOptionsSelected = (title: ButtonGroupTitles, value: ChallengeOptionsEnum) => {
        const report: IReportUnsubmitted = {
            challengeType: value,
            customerServing: this.state.report ? this.state.report.customerServing : undefined,
            timeSubmitted: "",
            userId: this.state.user.id,
        };

        const renderItems: any[] = report.challengeType === ChallengeOptionsEnum.UNDER_AGE ? this.UNDERAGE_RENDER
        : (report.challengeType === ChallengeOptionsEnum.SEASONAL_NTR ? this.SEASONAL_NTR_RENDER : this.INTOXICATED_OTHERS_RENDER);

        const scrollTo = report.challengeType === ChallengeOptionsEnum.SEASONAL_NTR ? ChallengeElements.SUBMIT : ChallengeElements.CUSTOMER_SERVING_OPTIONS;

        if (report.challengeType === ChallengeOptionsEnum.SEASONAL_NTR) {
            report.customerServing = undefined;
            if (!report.isCustomerServed) { report.isCustomerServed = IsCustomerServedEnum.NO; }
        }

        this.setState({
            renderItems,
            report,
            scrollTo,
        });

    }

    private onCustomerServingOptionsSelected = (title: ButtonGroupTitles, value: CustomerServingOptionsEnum) => {
        if (this.state.report) {
            const report: IReportUnsubmitted = {
                challengeType: this.state.report.challengeType,
                customerServing: value,
                timeSubmitted: this.state.report.timeSubmitted,
                userId: this.state.user.id,
            };
            const scrollTo: ChallengeElements = this.state.report.challengeType === ChallengeOptionsEnum.UNDER_AGE ? ChallengeElements.ID_OPTIONS : ChallengeElements.SUBMIT;

            if (!report.isCustomerServed) { report.isCustomerServed = IsCustomerServedEnum.NO; }

            this.setState({
                report,
                scrollTo,
            });
        }
    }

    private onIdOptionsSelected = (title: ButtonGroupTitles, value: IdOptionsEnum[]) => {
        if (this.state.report) {
            const report: IReportUnsubmitted = this.state.report;
            report.idProduced = value;
            this.setState({
                report,
                scrollTo: this.state.report.customerServing && this.state.report.customerServing === CustomerServingOptionsEnum.MULTIPLE ?
                    ChallengeElements.ID_OPTIONS : ChallengeElements.IS_CUSTOMER_SERVED_OPTIONS,
            });
        }
    }

    private onDescriptionFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (this.state.report) {
            const report: IReportUnsubmitted = this.state.report;
            report.description = e.currentTarget.value;
            this.setState({
                report,
            });
        }
    }

    private onIsCustomerServedSelected = (title: ButtonGroupTitles, value: IsCustomerServedEnum) => {
        if (this.state.report) {
            const report: IReportUnsubmitted = this.state.report;
            report.isCustomerServed = value;
            this.setState({
                report,
                scrollTo: ChallengeElements.SUBMIT,
            });
        }
    }

    private resetForm = () => {
        this.setState({
            backFunction: undefined,
            renderItems: [this.renderChallengeOptions],
            report: undefined,
        });
    }

    private onSubmit = () => {
        this.onReportCompleted(this.state.report);
    }

    private isFormFilled = (): boolean => {
        if (this.state.report && (this.state.report.customerServing || this.state.report.challengeType === ChallengeOptionsEnum.SEASONAL_NTR)) {
            if (this.state.report.challengeType === ChallengeOptionsEnum.UNDER_AGE) {
                return Boolean(this.state.report.idProduced && this.state.report.isCustomerServed);
            } else {
                return true;
            }
        }
        return false;
    }

    private onSwitchUser = () => {
        const userId: string = UsersService.getCurrentUserId();
        const report: IReportUnsubmitted | undefined = this.state.report;

        if (report) {
            report.userId = userId;
        }

        this.setState({
            report,
            user: {
                id: UsersService.getCurrentUserId(),
            },
        });
    }
}

export default Challenge;
