import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import ButtonFooter from "../components/ButtonFooter";
import SummaryNavBar from "../components/SummaryNavBar";
import { ButtonClassNames, RoutePaths, SummaryNavbarItems, Timeout } from "../constants/constants";
import FilterUsersDropdown from "../containers/FilterUsersDropdown";
import Header from "../containers/Header";
import ReportService from "../services/ReportService";
import { SidebarItems } from "../containers/Sidebar";
import SummaryTable from "../containers/SummaryTable";
import { IReport, IUser } from "../interfaces";
import AuthService from "../services/AuthService";

const SUMMARY_NAVBAR_ITEMS: SummaryNavbarItems[] = [SummaryNavbarItems.SUMMARY, SummaryNavbarItems.SUBMITTED_REPORTS];
const SUMMARY_SIDEBAR_ITEMS: SidebarItems[] = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];

interface ISummaryStates {
    filterUserId?: string;
    redirect?: RoutePaths;
    isAppOnline: boolean;
    isLoading: boolean;
    reports: IReport[];
}

class Summary extends Component<any, ISummaryStates> {
    constructor(props: any) {
        super(props);

        const authUser: IUser | void = AuthService.getUser();
        if (!authUser) {
            this.state = {
                isAppOnline: false,
                isLoading: false,
                redirect: RoutePaths.LOGIN,
                reports: [],
            };
        }
        this.state = {
            isAppOnline: navigator.onLine,
            isLoading: true,
            reports: ReportService.getLocalReports(),
        };

        window.ononline = this.onOnline;
        window.onoffline = this.onOffLine;
    }

    public async componentDidMount() {
        const authUser: IUser | void = AuthService.getUser();
        if (!authUser) {
            this.setState({
                redirect: RoutePaths.LOGIN,
            });
            return;
        }
        this.setState({
            isLoading: false,
            reports: navigator.onLine === true ? await ReportService.getAllReports() : this.state.reports,
        });
    }

    public render() {
        const Navigator: any = navigator;
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />); }
        return (
            <div id="summary-parent">
                <div id="app-header">
                    <Header
                        backFunction={this.backFunction}
                        path={RoutePaths.SUMMARY}
                        sidebarItems={SUMMARY_SIDEBAR_ITEMS}
                    />
                </div>
                <div id="app-header-filler" />
                <SummaryNavBar
                    items={SUMMARY_NAVBAR_ITEMS}
                    onClickHandler={this.onNavbarClick}
                    selected={SummaryNavbarItems.SUMMARY}
                />
                <FilterUsersDropdown onSelectUser={this.onSelectUser} isLoading={this.state.isLoading} online={navigator.onLine} />
                <CSSTransition
                    in={true}
                    appear={true}
                    classNames={"app-transition-slide-back"}
                    timeout={{ enter: Timeout }}
                    id="app-transition-slide"
                >
                    <div>
                        <div id="summary-table">
                            <SummaryTable filterUserId={this.state.filterUserId} allReports={this.state.reports} />
                        </div>
                    </div>
                </CSSTransition>
                <div id="summary-footer">
                    <ButtonFooter
                        onClick={this.onExport}
                        disabled={navigator.onLine === false || (Navigator.standalone === true)}
                        className={navigator.onLine && !((Navigator.standalone === true)) ? ButtonClassNames.RED : ButtonClassNames.RED_DISABLED}
                        renderButtonTextFunction={this.renderFooterButton}
                    />
                </div>
            </div>
        );
    }

    private renderFooterButton = () => {
        const Navigator: any = navigator;
        const exportText: string = Navigator.standalone === true ? "Export in Browser Only" : navigator.onLine === false ? "Go Online to Texpot" : "EXPORT";
        return (
            <div id="summary-footer-button-contents">
                <div>
                    {exportText}
                </div>
            </div>
        );
    }

    private onExport = () => {
        this.setState({
            redirect: RoutePaths.EXPORT_REPORTS,
        });
    }

    private onNavbarClick = (item: SummaryNavbarItems) => {
        if (item === SummaryNavbarItems.SUBMITTED_REPORTS) {
            this.setState({
                redirect: RoutePaths.PAST_REPORTS,
            });
        }
    }

    private backFunction = () => {
        this.setState({
            redirect: RoutePaths.CHALLENGE,
        });
    }

    private onSelectUser = (filterUserId: string | undefined) => {
        this.setState({
            filterUserId,
        });
    }

    private onOnline = async (e: any) => {
        await ReportService.sendSavedReports();

        this.setState({
            isAppOnline: navigator.onLine,
        });
    }

    private onOffLine = (e: any) => {

        this.setState({
            isAppOnline: navigator.onLine,
        });
    }
}

export default Summary;
