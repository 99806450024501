import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import GenericButton from "../components/GenericButton";
import Title from "../components/Title";
import { ButtonClassNames, RoutePaths, Timeout } from "../constants/constants";
import Header from "../containers/Header";
import { SidebarItems } from "../containers/Sidebar";
import SubmittedIcon from "../images/Submitted.svg";
import { IUser } from "../interfaces";
import AuthService from "../services/AuthService";
import UsersService from "../services/UsersService";

const SUBMITTED_SIDEBAR_ITEMS: SidebarItems[] = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];

const SUBMITTED_TIMEOUT_SECONDS = 5;
interface ISubmittedStates {
    countdownTimer: number;
    redirectPath?: string;
    showSidebar: boolean;
}

class Submitted extends Component<any, ISubmittedStates> {
    private timeoutId: any = null;
    constructor(props: any) {
        super(props);

        const authUser: IUser | void = AuthService.getUser();
        const userId: string = UsersService.getCurrentUserId();

        if (!authUser || userId === "") {
            this.state = {
                countdownTimer: 0,
                redirectPath: RoutePaths.LOGIN,
                showSidebar: false,
            };
            return;
        }

        this.state = {
            countdownTimer: SUBMITTED_TIMEOUT_SECONDS,
            showSidebar: false,
        };
    }

    public componentDidMount = () => {
        this.timeoutId = setTimeout(this.handleCountdown, 1000);
    }

    public componentWillUnmount = () => {
        clearTimeout(this.timeoutId);
    }

    public render() {
        if (this.state.redirectPath) {
            return (<Redirect to={this.state.redirectPath} />);
        } else {
            return (
                <div>
                    <div id="app-header">
                        <Header
                            path={RoutePaths.SUBMITTED}
                            sidebarItems={SUBMITTED_SIDEBAR_ITEMS}
                            onShowSidebar={this.onShowSidebar}
                            onHideSidebar={this.onHideSidebar}
                        />
                    </div>
                    <div id="app-header-filler" />
                    <CSSTransition
                        in={true}
                        appear={true}
                        classNames={"app-transition-slide"}
                        timeout={{ enter: Timeout }}
                    >
                        <div id="submitted-parent-wrapper">
                            <div id="submitted-parent">
                                <div id="submitted-contents">
                                    <div id="submitted-image-parent">
                                        <img id="submitted-image" src={SubmittedIcon} alt="" />
                                    </div>
                                    <Title text={"Report Submitted!"} />
                                    <div id="submitted-buttons">
                                        <div className="submitted-button">
                                            <GenericButton
                                                onClick={this.onNewReport}
                                                disabled={false}
                                                value={"NEW REPORT"}
                                                className={ButtonClassNames.SELECTED}
                                            />
                                        </div>
                                    </div>
                                    <div id="submitted-countdown">
                                        {`New report in ${this.state.countdownTimer}`}
                                    </div>
                                    <div id="submitted-activity-summary">
                                        <p onClick={this.onActivitySummary}>View Activity Summary</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            );
        }
    }

    private onShowSidebar = () => {
        this.setState({ showSidebar: true });
    }

    private onHideSidebar = () => {
        this.setState({ showSidebar: false });
    }

    private handleCountdown = () => {
        const countdown: number = this.state.countdownTimer;
        if (countdown === 1) {
            this.onNewReport();
        } else {
            this.timeoutId = setTimeout(this.handleCountdown, 1000);
            if (this.state.showSidebar === false) {
                this.setState({
                    countdownTimer: countdown - 1,
                });
            }
        }
    }

    private onActivitySummary = () => {
        this.setState({
            countdownTimer: 0,
            redirectPath: RoutePaths.SUMMARY,
        });
    }

    private onNewReport = () => {
        this.setState({
            countdownTimer: 0,
            redirectPath: RoutePaths.CHALLENGE,
        });
    }
}

export default Submitted;
