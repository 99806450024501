import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

import BackIcon from "../images/Back.svg";
import UserCardIcon from "./UserCardIcon";

interface IHeaderLayoutProps {
    text: string;
    emphasisText?: string;
    backFunction?: any;
    sidebarFunction?: any;
    currentUserId: string;
    onTitleClick?: any;
}

class HeaderLayout extends Component<IHeaderLayoutProps> {

    public render() {
        return (
            <div id="header-layout-parent">
                <div id="header-layout-contents">
                    <Row>
                        <Col xs={2} className="header-layout-column">
                            {this.props.backFunction ? (<div id="header-layout-back" onClick={this.props.backFunction}><img src={BackIcon} alt={"<"} /></div>) : null}
                        </Col>
                        <Col xs={8} className="header-layout-column">
                            <div id="header-layout-title" onClick={this.props.onTitleClick} style={{cursor: this.props.onTitleClick ? "pointer" : undefined}}>
                                <span>{this.props.text}</span><span id="header-layout-emphasis">{this.props.emphasisText}</span>
                            </div>
                        </Col>
                        <Col xs={2}>
                            {this.renderUserCardIcon()}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    private renderUserCardIcon = () => {
        if (this.props.sidebarFunction) {
            return (
                <div id="header-layout-sidebar" onClick={this.props.sidebarFunction}><UserCardIcon id={this.props.currentUserId} /></div>
            );
        } else {
            return null;
        }
    }
}

export default HeaderLayout;
