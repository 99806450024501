import React, { Component } from "react";
import { Redirect } from "react-router";
import { RoutePaths, SupportDocUrls, Timeout } from "../constants/constants";

import { Dropdown } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import store from "store";
import Divider from "../components/Divider";
import SidebarItem from "../components/SidebarItem";
import UserCardDropDown from "../components/UserCardDropDown";
import ActivitySummaryIcon from "../images/ActivitySummary.svg";
import CloseIcon from "../images/Close.svg";
import ExportIcon from "../images/Export.svg";
import NewReportIcon from "../images/NewReport.svg";
import PastReportsIcon from "../images/PastReports.svg";
import UserIcon from "../images/User.svg";
import { IUser } from "../interfaces";
import UsersService from "../services/UsersService";
import MsalAuthService from "./../services/MsalAuthService";
import UserCard, { UserCardSizes } from "./UserCard";

import HelpIcon from "../icons/Help.svg";
import OfflineIcon from "../images/Offline.svg";
import OnlineIcon from "../images/Online.svg";
import AuthApiService from "../services/api/AuthApiService";
import AuthService from "../services/AuthService";

export enum SidebarItems {
    NEW_REPORT,
    PAST_REPORTS,
    EXPORT_REPORTS,
    SWITCH_USER,
    ACTIVITY_SUMMARY,
}

const DISABLE_ON_NO_USER: SidebarItems[] = [SidebarItems.NEW_REPORT];

interface ISidebarProps {
    isLoggedIn: boolean;
    hideUsers?: boolean;
    sidebarItems: SidebarItems[];
    show: boolean;
    onClose: any;
    path: string;
    onSwitchUser?: any;
    onSamePageClickHandler?: any;
    isOnline: boolean;
}

interface ISidebarState {
    redirect?: string;
    allUsers: IUser[];
    currentUserId: string;
    isAdmin: boolean;
    showUserPopup?: boolean;
    storeId?: string;
}

class Sidebar extends Component<ISidebarProps, ISidebarState> {

    constructor(props: ISidebarProps) {
        super(props);
        this.state = {
            allUsers: UsersService.getUsers(),
            currentUserId: UsersService.getCurrentUserId(),
            isAdmin: false,
        };
    }

    public async componentDidMount() {
        const role = await AuthApiService.getRole();
        this.setState({
            isAdmin: store.get("stores") !== undefined && store.get("stores").length > 0,
        });
        if (role && role.storeId) {
            if (role.storeId.lastIndexOf("@") !== -1) { role.storeId = role.storeId.substring(0, role.storeId.lastIndexOf("@")); }
            this.setState({
                storeId: role.storeId,
            });
        }
    }

    public componentDidUpdate(prevProps: ISidebarProps) {
        if (prevProps.show === false && this.props.show === true) {
            this.setState({
                allUsers: UsersService.getUsers(),
            });
        }
    }

    public render() {
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />); }

        return (
            <div id="sidebar-parent">
                {this.props.show ? (<div id="sidebar-overlay" onClick={this.props.onClose} />) : <div id="sidebar-filler" />}
                <CSSTransition
                    in={this.props.show}
                    classNames={"sidebar-transition"}
                    timeout={Timeout}
                    unmountOnExit={true}
                >
                    <div id="sidebar-contents">
                        <div className="sidebar-top-container">
                            <div id="sidebar-images">
                                <img
                                    className="online-icon"
                                    src={this.props.isOnline === true ? OnlineIcon : OfflineIcon}
                                    alt=""
                                    style={{ opacity: this.props.isOnline === true ? 1 : 0.5 }}
                                />
                                <img src={CloseIcon} onClick={this.props.onClose} alt="Close" />
                            </div>
                            <div id="sidebar-store-id">
                                {this.state.storeId}
                            </div>
                        </div>
                        <div id="sidebar-links">
                            <div id="sidebar-items">
                                {this.props.sidebarItems.map(this.renderListItem)}
                            </div>
                            <Divider />
                            <div hidden={this.props.hideUsers} id="sidebar-switch-user-header">
                                <div id="sidebar-switch-user-text">
                                    Switch User
                                    </div>
                                <div id="sidebar-switch-user-more">
                                    <Dropdown alignRight={true} className="user-card-dropdown-div">
                                        <Dropdown.Toggle as={UserCardDropDown} id="user-card-dropdown-div" />

                                        <Dropdown.Menu id="sidebar-dropdown-menu">
                                            <Dropdown.Item eventKey="1" onClick={this.onSwitchUser}>Manage Users</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div hidden={this.props.hideUsers} id="sidebar-switch-user-div">
                                {this.state.allUsers.map(this.renderUserItem)}
                            </div>
                        </div>
                        <div id="sidebar-online-offline">
                            <a
                                className="helpfooter-text"
                                href={this.state.isAdmin ? SupportDocUrls.CORPERATE_ADMIN_DOC : SupportDocUrls.STORE_ADMIN_DOC}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img className="helpicon-sidebar" src={HelpIcon} alt="" />
                                Help
                            </a>
                        </div>
                        <div id="sidebar-logout" onClick={this.logout}>
                            Logout
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }

    private logout = () => {
        const user = AuthService.getUser();
        if (!user) {
            this.redirect(RoutePaths.LOGIN);
        } else {
            MsalAuthService.unauthenticate();
        }
    }

    private renderUserItem = (user: IUser, key: number) => {
        return (
            <div className="sidebar-user-card-div" key={key}>
                <UserCard
                    name={user.id}
                    selected={user.id === this.state.currentUserId}
                    onClick={this.quickSwitchUser}
                    size={UserCardSizes.SMALL}
                />
            </div>
        );
    }

    private renderListItem = (item: SidebarItems, key: number) => {
        let imgSrc: string = "";
        let onClick: any = null;
        let text: string = "";

        switch (item) {
            case SidebarItems.NEW_REPORT:
                imgSrc = NewReportIcon;
                onClick = this.onNewReport;
                text = "New Challenge";
                break;
            case SidebarItems.PAST_REPORTS:
                imgSrc = PastReportsIcon;
                onClick = this.onPastReports;
                text = "Submitted Reports";
                break;
            case SidebarItems.EXPORT_REPORTS:
                imgSrc = ExportIcon;
                onClick = this.onExportReports;
                text = "Export Reports";
                break;
            case SidebarItems.SWITCH_USER:
                imgSrc = UserIcon;
                onClick = this.onSwitchUser;
                text = "Manage Users";
                break;
            case SidebarItems.ACTIVITY_SUMMARY:
                imgSrc = ActivitySummaryIcon;
                onClick = this.onActivitySummary;
                text = "Activity Summary";
        }

        return (
            <div
                key={key}
                className={`${this.state.currentUserId === "" && DISABLE_ON_NO_USER.includes(item) ? "sidebar-items-disabled" : ""}`}
                onClick={this.state.currentUserId === "" && DISABLE_ON_NO_USER.includes(item) ? this.toggleUserPopup : undefined}
            >
                {this.renderPopup(item)}
                <SidebarItem
                    imgSrc={imgSrc}
                    onClick={this.state.currentUserId === "" && DISABLE_ON_NO_USER.includes(item) ? null : onClick}
                    text={text}
                    key={key}
                />
            </div>
        );
    }

    private renderPopup = (item: SidebarItems) => {
        if (this.state.showUserPopup && this.state.showUserPopup === true && DISABLE_ON_NO_USER.includes(item)) {
            return (<span className="sidebar-items-popup">No user selected!</span>);
        } else {
            return null;
        }
    }

    private toggleUserPopup = () => {
        this.setState({
            showUserPopup: !this.state.showUserPopup,
        });
    }

    private onNewReport = () => {
        this.redirect(RoutePaths.CHALLENGE);
    }

    private onPastReports = () => {
        this.redirect(RoutePaths.PAST_REPORTS);
    }

    private onExportReports = () => {
        this.redirect(RoutePaths.EXPORT_REPORTS);
    }

    private onSwitchUser = () => {
        this.redirect(RoutePaths.USERS);
    }

    private onActivitySummary = () => {
        this.redirect(RoutePaths.SUMMARY);
    }

    private quickSwitchUser = (id: string) => {
        UsersService.setCurrentUser(id);
        this.setState({
            currentUserId: id,
        });
        if (this.props.onSwitchUser) {
            this.props.onSwitchUser();
        }
        this.props.onClose();
    }

    private redirect = (path: RoutePaths) => {
        if (path === this.props.path) {
            if (this.props.onSamePageClickHandler) {
                this.props.onSamePageClickHandler();
            }
            this.props.onClose();
        } else {
            this.setState({
                redirect: path,
            });
        }
    }
}

export default Sidebar;
