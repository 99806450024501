import React, { Component } from "react";
import ButtonGroup from "../components/ButtonGroup";
import { ButtonGroupColumns, ButtonGroupTitles } from "../constants/constants";
import { IdOptionsEnum, IdOptionsMapper } from "../interfaces";
import BirthdateDeadline from "./BirthdateDeadline";

const ID_OPTIONS: string[] = Object.values(IdOptionsEnum);

interface IIdOptionsProps {
    onOptionsSelected: any;
    initialValues?: IdOptionsEnum[];
    disabled?: boolean;
    showBirthdate: boolean;
    multiple: boolean;
}

class IdOptions extends Component<IIdOptionsProps> {

    public render() {
        return (
            <div id="id-options-parent">
                {this.props.showBirthdate === true ? (<BirthdateDeadline />) : null}
                <ButtonGroup
                    title={ButtonGroupTitles.ID_OPTIONS}
                    options={ID_OPTIONS}
                    onOptionSelectHandler={this.onSelected}
                    nColumns={ButtonGroupColumns.TWO}
                    initialSelectedValues={this.props.initialValues ? this.props.initialValues : undefined}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    multiple={this.props.multiple}
                    optionMapper={IdOptionsMapper}
                />
            </div>
        );
    }

    private onSelected = (title: ButtonGroupTitles, value: IdOptionsEnum[]) => {
        if (this.props.multiple === false) {
            this.props.onOptionsSelected(title, [value[0]]);
        } else {
            this.props.onOptionsSelected(title, value);
        }
    }
}

export default IdOptions;
