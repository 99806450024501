import DateFormat from "dateformat";
import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import store from "store";
import ButtonFooter from "../components/ButtonFooter";
import GenericButton from "../components/GenericButton";
import Title from "../components/Title";
import { ButtonClassNames, ExportReportsSchema, RoutePaths, Timeout } from "../constants/constants";
import Header from "../containers/Header";

import FileExportedIcon from "../images/FileExportedIcon.svg";
import { IUser } from "../interfaces";
import AuthService from "../services/AuthService";
import ReportsService from "../services/ReportService";
import UsersService from "../services/UsersService";

interface ISubmittedStates {
    redirectPath?: string;
    csvData: string;
    dateFrom: string;
    dateTo: string;
    emailSent: boolean;
    loading: boolean;
    standalone: boolean; // Use to detect if you are on a PWA or not. downloading files is not currently well supported on PWAs, so we disable it.
    showDownloadDisabledText: boolean;
    storesSelected: string[];
}

class ExportSubmitted extends Component<any, ISubmittedStates> {
    constructor(props: any) {
        super(props);
        const Navigator: any = navigator;

        this.state = {
            csvData: "",
            dateFrom: store.get(ExportReportsSchema.EXPORT_DATE_FROM, ""),
            dateTo: store.get(ExportReportsSchema.EXPORT_DATE_TO, ""),
            emailSent: false,
            loading: false,
            redirectPath: undefined,
            showDownloadDisabledText: false,
            standalone: Navigator.standalone,
            storesSelected: store.get(ExportReportsSchema.EXPORT_STORE_IDS, ""),
        };
    }

    public componentDidMount = async () => {
        const user: IUser | void = AuthService.getUser();
        if (!user) {
            await this.setState({
                redirectPath: RoutePaths.USERS,
            });
            return;
        }

        const stores = await UsersService.setRoles();
        if (!stores.length) {
            this.setState({
                redirectPath: RoutePaths.USERS,
            });
        } else {
            if (!this.state.csvData.length) {
                const csvData = await ReportsService.getReportsCsvWithStoreId(this.state.dateFrom, this.state.dateTo, this.state.storesSelected);
                this.setState({
                    csvData,
                });
            }
        }
    }

    public render() {
        if (this.state.redirectPath) {
            return (<Redirect to={this.state.redirectPath} />);
        } else {
            return (
                <div id="export-submitted-parent">
                    <div id="app-header">
                        <div id="app-header">
                            <Header
                                path={RoutePaths.EXPORT_SUBMITTED_ADMIN}
                                sidebarItems={[]}
                                sidebarHideUsers={true}
                            />
                        </div>
                    </div>
                    <div id="app-header-filler" />
                    <CSSTransition
                        in={true}
                        appear={true}
                        classNames={"app-transition-slide"}
                        timeout={{ enter: Timeout }}
                    >
                        <div id="submitted-parent">
                            <div id="submitted-contents-wrapper">
                                <div id="export-submitted-contents">
                                    <div id="submitted-image-parent">
                                        <img id="submitted-image" src={FileExportedIcon} alt="" />
                                    </div>
                                    <Title text={"File Ready!"} />
                                    {/* <div className="export-submitted-view-button"><a href="#0">VIEW</a></div> */}
                                    <div id="submitted-buttons">
                                        <div className="submitted-button">
                                            <GenericButton
                                                onClick={this.onDownloadButtonSubmitted}
                                                disabled={this.state.loading || this.state.standalone === true}
                                                value={"SAVE ON DEVICE"}
                                                className={ButtonClassNames.SELECTED}
                                            />
                                        </div>
                                        <div className="submitted-button">
                                            <GenericButton
                                                onClick={this.onEmailButtonSubmitted}
                                                disabled={this.state.loading}
                                                value={"EMAIL REPORT"}
                                                className={ButtonClassNames.SELECTED}
                                            />
                                        </div>
                                        <p className="submitted-sent-text"> {this.state.standalone === true ? "Save on device only available in browser" : null} </p>
                                        <div> {this.state.emailSent ? <p className="submitted-sent-text"> Sent! </p> : null} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    {/* Done button */}
                    {this.renderFooter()}
                </div>
            );
        }
    }

    public doneButtonHandler = () => {
        this.setState({
            redirectPath: RoutePaths.EXPORT_REPORTS_ADMIN,
        });
    }

    public renderExportButtonText = () => {
        return (
            <p className="button-footer-export-button-text"> DONE </p>
        );
    }

    private onDownloadButtonSubmitted = async () => {
        const file = new Blob([this.state.csvData], {type: "text/csv;charset=utf-8"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = `LCO_CR_Report_from-${DateFormat(this.state.dateFrom, "yyyy-mm-dd")}-to-${DateFormat(this.state.dateTo, "yyyy-mm-dd")}.csv`;
        document.body.appendChild(link);
        link.click();
    }

    private pwaOnDownloadButtonSubmitted = async () => {
        this.setState({
            showDownloadDisabledText: true,
        });
    }

    private onEmailButtonSubmitted = async () => {

        this.setState({
            loading: true,
        });

        const emailResonse = await ReportsService.sendCSVDataEmailWithStoreId(this.state.dateFrom, this.state.dateTo, this.state.storesSelected);

        this.setState({
            emailSent: emailResonse,
            loading: false,
        });
    }

    private renderFooter = () => {
        return (
            <div id="export-submitted-footer">
                <ButtonFooter
                    className={this.state.loading ? ButtonClassNames.RED_DISABLED : ButtonClassNames.RED}
                    onClick={this.doneButtonHandler}
                    disabled={this.state.loading}
                    renderButtonTextFunction={this.renderExportButtonText}
                />
            </div>
        );
    }

}

export default ExportSubmitted;
