import React, { Component } from "react";
import { SupportDocUrls } from "../constants/constants";
import HelpIcon from "../icons/Help.svg";

class HelpFooter extends Component {

    public render() {
        return (
            <div className="helpfooter-container">
                <a className="helpfooter-text" href={SupportDocUrls.STORE_ADMIN_DOC} target="_blank" rel="noopener noreferrer">
                    <img className="helpicon" src={HelpIcon} alt="" /> Help
                </a>
            </div>
        );
    }
}

export default HelpFooter;
