import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import HelpFooter from "../components/HelpFooter";
import Title from "../components/Title";
import { ButtonClassNames, RoutePaths, Timeout } from "../constants/constants";
import Header from "../containers/Header";
import UserCard from "../containers/UserCard";
import { IServiceResponse, IUser } from "../interfaces";
import AuthService from "../services/AuthService";
import ReportService from "../services/ReportService";
import UsersService from "../services/UsersService";

import { Spinner } from "react-bootstrap";
import GenericButton from "../components/GenericButton";
import InputField from "../components/InputField";
import { SidebarItems } from "../containers/Sidebar";
import { ReactComponent as AddIcon } from "../images/Add.svg";

const SELECT_USERS_SIDEBAR_ITEMS: SidebarItems[] = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];

interface ISelectUsersState {
    users: IUser[];
    currentUserId: string;
    redirect?: string;
    addUser: boolean;
    newUser: string;
    addUserErrorMessage?: string;
    isAppOnline: boolean;
    loadingUsers: boolean;
}

class SelectUsers extends Component<any, ISelectUsersState> {

    private USERS_ON_MORE_OPTIONS: any[];

    constructor(props: any) {
        super(props);

        window.ononline = this.onOnline;
        window.onoffline = this.onOffLine;

        this.USERS_ON_MORE_OPTIONS = [];

        const authUser: IUser | void = AuthService.getUser();
        if (!authUser) {
            this.state = {
                addUser: false,
                currentUserId: "",
                isAppOnline: navigator.onLine,
                loadingUsers: true,
                newUser: "",
                redirect: RoutePaths.LOGIN,
                users: [],
            };
            return;
        }

        this.state = {
            addUser: false,
            currentUserId: UsersService.getCurrentUserId(),
            isAppOnline: navigator.onLine,
            loadingUsers: true,
            newUser: "",
            users: UsersService.getUsers(),
        };
    }

    public componentDidMount = async () => {
        if (!this.state.redirect) {
            this.setState({
                loadingUsers: false,
                users: navigator.onLine ? await UsersService.getAllUsersApi() : this.state.users,
            });
        }
    }

    public render() {
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />); }
        return (
            <div id="select-users-parent">
                <div id="app-header">
                    <Header
                        path={RoutePaths.USERS}
                        sidebarItems={SELECT_USERS_SIDEBAR_ITEMS}
                        onSwitchUser={this.onQuickSwitchUser}
                    />
                </div>
                <div id="app-header-filler" />
                <CSSTransition
                    in={true}
                    appear={true}
                    classNames={"app-transition-slide"}
                    timeout={{ enter: Timeout }}
                >
                    <div id="select-users-content">
                        <div id="select-users-title">
                            <Title text="Select User" />
                            {this.renderSpinner()}
                        </div>
                        {this.state.users.map(this.renderUserCard)}
                        <div className="select-users-user-card-div">
                            {this.renderAddUser()}
                        </div>
                        <HelpFooter/>

                    </div>
                </CSSTransition>
            </div>
        );
    }

    private renderUserCard = (user: IUser, index: number) => {
        return (
            <div className="select-users-user-card-div" key={index}>
                <UserCard
                    isAppOnline={this.state.isAppOnline}
                    onClick={this.setCurrentUser}
                    name={user.id}
                    selected={user.id === this.state.currentUserId}
                    dropdown={navigator.onLine}
                    onDeleteUser={this.DeleteUser}
                />
            </div>
        );
    }

    private renderSpinner = () => {
        if (this.state.loadingUsers === true) {
            return (
                <div id="select-users-spinner">
                    <Spinner animation="border" role="status" size="sm">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            );
        } else {
            return null;
        }
    }

    private setCurrentUser = (user: string) => {
        UsersService.setCurrentUser(user);
        this.setState({
            redirect: RoutePaths.CHALLENGE,
        });
    }

    private onQuickSwitchUser = () => {
        this.setState({
            redirect: RoutePaths.CHALLENGE,
        });
    }

    private handleAddUserButton = () => {
        this.setState({
            addUser: true,
        });
    }

    private renderAddUser = () => {
        if (!this.state.addUser) {
            return (
                <div className={"select-users-add-card"} onClick={this.state.isAppOnline ? this.handleAddUserButton : () => null}>
                    <div className={this.state.isAppOnline ? "select-users-add-card-photo" : "select-users-add-card-photo-disabled"}>
                        <AddIcon />
                    </div>
                    <div className="select-users-add-card-name">
                        <div id="select-users-add-text">{this.state.isAppOnline ? "ADD USER" : "Go Online To Add Users"}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="select-users-add-input-parent">
                    <div className="select-users-add-input-fields">
                        <div className="select-users-add-input">
                            <InputField
                                placeholder="XY#"
                                onKeyPress={this.onAddUserKeyPress}
                                onChange={this.onAddUserInputChange}
                                autoFocus={true}
                                onFocusOut={this.onAddUserFocusOut}
                            />
                        </div>
                        <div className="select-users-add-input-button">
                            <GenericButton
                                value="ADD"
                                disabled={!(/^[A-Za-z]{2}[0-9]$/g).test(this.state.newUser)}
                                className={ButtonClassNames.BLACK_DISABLE}
                                onClick={this.onAddUser}
                            />
                        </div>
                    </div>
                    <div className="select-users-add-input-hints">
                        {this.state.addUserErrorMessage && this.state.addUserErrorMessage !== "" ? (<p id="select-users-add-error">{this.state.addUserErrorMessage}</p>) : null}
                        <p>X - First letter of FIRST name</p>
                        <p>Y - First letter of LAST NAME</p>
                        <p># - Number 0-9</p>
                    </div>
                </div>
            );
        }
    }

    private onAddUserKeyPress = (e: any) => {
        const allCharacters = /[A-Za-z]+/g;
        const allNums = /[0-9]+/g;
        const key: string = e.key;
        if (e.target && key !== "Backspace" && key !== "Delete" && key !== "ArrowLeft" && key !== "ArrowRight") {  // Allow backspace and deletes
            if (!e.target.value || e.target.value.length <= 1) {
                if (!allCharacters.test(key)) {
                    e.preventDefault();
                }
            } else if (e.target.value.length === 2) {
                if (!allNums.test(key)) {
                    e.preventDefault();
                }
            } else {
                e.preventDefault();
            }
        }
    }

    private onAddUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            newUser: e.target.value,
        });
    }

    private onAddUser = () => {
        this.AddUser(this.state.newUser.toUpperCase());
    }

    private AddUser = async (id: string) => {
        const newUser: IUser = {
            id,
        };

        const addUserResponse: IServiceResponse = await UsersService.addUser(newUser);

        if (addUserResponse.success === true) {
            this.setState({
                addUser: false,
                addUserErrorMessage: undefined,
                newUser: "",
                users: UsersService.getUsers(),
            });
        } else if (addUserResponse.message) {
            this.setState({
                addUserErrorMessage: addUserResponse.message,
            });
        }
    }

    private DeleteUser = async (id: string) => {
        if (!this.state.isAppOnline) { return; }
        await UsersService.deleteUser(id);
        this.setState({
            users: UsersService.getUsers(),
        });
    }

    private onAddUserFocusOut = () => {
        if (!this.state.newUser) {
            this.setState({
                addUser: false,
                addUserErrorMessage: undefined,
            });
        }
    }

    private onOnline = async (e: any) => {
        await ReportService.sendSavedReports();

        this.setState({
            isAppOnline: navigator.onLine,
        });
    }

    private onOffLine = (e: any) => {
        this.setState({
            isAppOnline: navigator.onLine,
        });
    }
}

export default SelectUsers;
