import { RequestMethod, endPoint } from "../../constants/constants";
import { IReport, IReportUnsubmitted } from "../../interfaces";
import BaseService from "../BaseService";

class ReportsApiService {

    // Gets reports from the API. The API filters out reports that were not made by the logged in user.
    // Dates should have a timezone. For example: 2012-01-01T05:00:00.000Z
    public getReports = async (dateFrom?: Date, dateTo?: Date): Promise<IReport[]> => {
        const query = dateFrom && dateTo ? {dateFrom, dateTo} : {};
        const reportsResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.GET, endPoint, {}, query);
        return reportsResponse.body;
    }

    public getReportById = async (id: string): Promise<IReport> => {
        const reportsResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.GET, `${endPoint}/${id}`);
        return reportsResponse.body;
    }

    public getAllReports = async (): Promise<IReport[]> => {
        const dateFrom = new Date(new Date().setFullYear(2000)).toISOString();
        const dateTo = new Date().toISOString();
        const query = {
            dateFrom,
            dateTo,
        };

        const reportsResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.GET, endPoint, {}, query);
        const reports = reportsResponse.body;
        reports.map((report: any) => {
            report.reportId = parseInt(report.reportId, 10);
            return report;
        });
        return reportsResponse.body;
    }

    public createReport = async (report: IReportUnsubmitted): Promise<IReport> => {
        const reportsResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.POST, endPoint, report);
        return reportsResponse.body;
    }

    public getCSVData = async (dateFromString: string, dateToString: string) => {
        const reportsResponse = await BaseService.sendAuthenticatedRequestRaw(RequestMethod.POST, `${endPoint}/csv`, {}, { dateFrom: dateFromString, dateTo: dateToString });
        const data = await reportsResponse.text();
        return data;
    }

    public sendCSVDataEmail = async (dateFromString: string, dateToString: string) => {
        const reportsResponse = await BaseService.sendAuthenticatedRequestRaw(RequestMethod.POST, `${endPoint}/email`, {}, { dateFrom: dateFromString, dateTo: dateToString });
        const data = await reportsResponse.text();
        return data;
    }

    public getCSVDataByStore = async (dateFromString: string, dateToString: string, storesSelected: string[]) => {
        const body = storesSelected;
        const reportsResponse = await BaseService.sendAuthenticatedRequestRaw(RequestMethod.POST, `${endPoint}/csv`, body, { dateFrom: dateFromString, dateTo: dateToString });
        const data = await reportsResponse.text();
        return data;
    }

    public sendCSVDataEmailByStore = async (dateFromString: string, dateToString: string, storesSelected: string[]) => {
        const body = storesSelected;
        const reportsResponse = await BaseService.sendAuthenticatedRequestRaw(RequestMethod.POST, `${endPoint}/email`, body, { dateFrom: dateFromString, dateTo: dateToString });
        const data = await reportsResponse.text();
        return data;
    }
}

export default new ReportsApiService();
