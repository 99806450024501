import DateFormat from "dateformat";
import React, { Component } from "react";
import { Redirect } from "react-router";
import Divider from "../components/Divider";
import PastReportsListItem from "../components/PastReportsListItem";
import PastReportsTableHeader from "../components/PastReportsTableHeader";
import { RoutePaths } from "../constants/constants";
import { IReport } from "../interfaces";
interface IPastReportsTableProps {
    reports: IReport[];
    date: Date;
    clickHandler?: (report: IReport) => void;
    showStores?: boolean;
}

interface IPastReportsTableStates {
    redirect?: string;
}

const DATE_STRINGS: string[] = [
    "Today",
    "Yesterday",
];

class PastReportsTable extends Component<IPastReportsTableProps, IPastReportsTableStates> {
    constructor(props: IPastReportsTableProps) {
        super(props);
        this.state = {};
    }

    public render() {
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />); }
        return (
            <div className="past-reports-table-parent">
                <PastReportsTableHeader
                    date={this.getDateString(this.props.date)}
                />
                <Divider />
                {[...this.props.reports].reverse().map(this.renderListItem)}
            </div>
        );
    }

    private renderListItem = (report: IReport, index: number) => {
        const listEntries = [this.props.showStores ? report.storeId || "" : report.userId,
        `${report.reportId || ""}`,
        `${report.challengeType || ""}`,
        `${report.isCustomerServed || ""}`];
        return (
            <PastReportsListItem
                entries={listEntries}
                key={index}
                onClick={this.onClickRow}
                report={report}
            />
        );
    }

    private onClickRow = (report: IReport) => {
        if (this.props.clickHandler) {
            this.props.clickHandler(report);
        } else {
            this.setState({
                redirect: `${RoutePaths.VIEW_REPORT}/${report.reportId}`,
            });
        }
    }

    private getDateString = (date: Date): string => {
        const today = new Date();
        if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && DATE_STRINGS[today.getDate() - date.getDate()]) {
            return DATE_STRINGS[today.getDate() - date.getDate()];
        }
        return DateFormat(date, "mmm.d.yy");
    }

    private getFormattedTime = (dateString: string): string => {
        const date = new Date(dateString);
        return DateFormat(date, "h:MM TT");
    }
}

export default PastReportsTable;
