export const formatDate = (date: Date): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "long",
        year: "numeric",
    };
    return date.toLocaleDateString("en-US", dateOptions);
};

export const formatDateWithTime = (date: Date): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        month: "long",
        year: "numeric",
    };
    return date.toLocaleDateString("en-US", dateOptions);
};
