import React, { Component } from "react";
import { Collapse, Dropdown, Spinner } from "react-bootstrap";
import FilterUsersDropdownButton from "../components/FilterUsersDropdownButton";
import UserCardIcon from "../components/UserCardIcon";
import UsersService from "../services/UsersService";

import DropdownArrowIcon from "../images/DropdownArrow.svg";

interface IFilterUsersDropdownProps {
    onSelectUser: any;
    initialUserId?: string;
    isLoading: boolean;
    online: boolean;
}

interface IFilterUsersDropdownStates {
    allUserIds: string[];
    selectedUserId?: string;
}

class FilterUsersDropdown extends Component<IFilterUsersDropdownProps, IFilterUsersDropdownStates> {
    constructor(props: IFilterUsersDropdownProps) {
        super(props);
        this.state = {
            allUserIds: [],
            selectedUserId: this.props.initialUserId,
        };
    }

    public componentDidMount = async () => {
        this.setState({
            allUserIds: await UsersService.getArchivedUserIds(),
        });
    }

    public render() {
        return (
            <div id="filter-users-dropdown-div">
                <Dropdown id="filter-users-dropdown" onSelect={this.onSelectUser} alignRight={true}>
                    <Dropdown.Toggle as={FilterUsersDropdownButton} id="filter-users-dropdown-toggle">
                        <div id="filter-users-dropdown-toggle-layout">
                            <div>
                                <UserCardIcon id={this.state.selectedUserId} />
                            </div>
                            <div>{this.state.selectedUserId ? this.state.selectedUserId : "All Users"}</div>
                            <div>
                                <img src={DropdownArrowIcon} alt="" />
                            </div>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey={undefined}>{"All Users"}</Dropdown.Item>
                        {this.state.allUserIds.map(this.renderDropdownItem)}
                    </Dropdown.Menu>
                </Dropdown>
                {this.renderSpinner()}
                <div>
                    <Collapse in={this.props.online === false}>
                        <div>
                            <div id="filter-users-dropdown-offline-text">Go online to sync with offline reports</div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }

    private renderSpinner = () => {
        if (this.props.isLoading === true) {
            return (
                <Spinner id="filter-users-dropdown-spinner" size="sm" animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        } else {
            return null;
        }
    }

    private renderDropdownItem = (userId: string, index: number) => {
        return (
            <Dropdown.Item eventKey={userId} key={index}>{userId}</Dropdown.Item>
        );
    }

    private onSelectUser = (eventKey: any) => {
        this.setState({
            selectedUserId: eventKey,
        });
        this.props.onSelectUser(eventKey);
    }
}

export default FilterUsersDropdown;
