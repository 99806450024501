import React, { Component } from "react";
import TitleBodySmall from "../components/TitleBodySmall";
import { LEGAL_DRINKING_AGE } from "../constants/constants";
import { formatDate } from "../constants/functions";

interface IBirthdateDeadlineStates {
    dateString: string;
}

class BirthdateDeadline extends Component<any, IBirthdateDeadlineStates> {
    constructor(props: any) {
        super(props);
        const date: Date = new Date();
        date.setFullYear(date.getFullYear() - LEGAL_DRINKING_AGE);
        this.state = {
            dateString: formatDate(date),
        };
    }

    public render() {
        return (
            <div id="birthdate-deadline-parent">
                <TitleBodySmall
                    title={"19+ BORN ON/BEFORE"}
                    body={this.state.dateString}
                />
            </div>
        );
    }
}

export default BirthdateDeadline;
