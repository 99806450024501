import React, { Component } from "react";
import { Redirect } from "react-router";
import HeaderLayout from "../components/HeaderLayout";
import { RoutePaths } from "../constants/constants";
import UsersService from "../services/UsersService";
import Sidebar, { SidebarItems } from "./Sidebar";

interface IHeaderProps {
    backFunction?: any;
    sidebarItems?: SidebarItems[];
    path: RoutePaths;
    onSwitchUser?: any;
    onSamePageClickHandler?: any;
    onShowSidebar?: any;
    onHideSidebar?: any;
    sidebarHideUsers?: boolean;
}

interface IHeaderState {
    redirect?: string;
    showSidebar: boolean;
    currentUserId: string;
}

class Header extends Component<IHeaderProps, IHeaderState> {

    constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            currentUserId: UsersService.getCurrentUserId(),
            showSidebar: false,
        };
    }

    public componentDidMount = () => {
        if (this.state.showSidebar === true) {
            this.hideSidebar();
        }
    }

    public render() {
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />); }

        return (
            <div id="header-parent">
                <div id="header-sidebar-parent">
                    <Sidebar
                        path={this.props.path}
                        show={this.state.showSidebar}
                        sidebarItems={this.props.sidebarItems ? this.props.sidebarItems : []}
                        onClose={this.hideSidebar}
                        isLoggedIn={true}
                        onSwitchUser={this.onSwitchUser}
                        onSamePageClickHandler={this.props.onSamePageClickHandler}
                        isOnline={navigator.onLine}
                        hideUsers={this.props.sidebarHideUsers}
                    />
                </div>
                <div id="header-contents-parent">
                    <HeaderLayout
                        text={"Challenge & "}
                        emphasisText={"Refusal"}
                        backFunction={this.props.backFunction}
                        sidebarFunction={this.props.sidebarItems ? this.showSidebar : null}
                        currentUserId={this.state.currentUserId}
                        onTitleClick={this.onTitleClick}
                    />
                </div>
            </div>
        );
    }

    private showSidebar = () => {
        if (this.props.onShowSidebar) { this.props.onShowSidebar(); }
        this.setState({
            showSidebar: true,
        });
    }

    private hideSidebar = () => {
        if (this.props.onHideSidebar) { this.props.onHideSidebar(); }
        this.setState({
            showSidebar: false,
        });
    }

    private onSwitchUser = () => {
        this.setState({
            currentUserId: UsersService.getCurrentUserId(),
        });

        if (this.props.onSwitchUser) {
            this.props.onSwitchUser();
        }
    }

    private onTitleClick = () => {
        if (this.state.currentUserId === "" && this.props.path === RoutePaths.USERS) {
            return;
        } else if (this.props.path === RoutePaths.EXPORT_REPORTS_ADMIN || this.props.path === RoutePaths.EXPORT_SUBMITTED_ADMIN) {
            if (this.props.path === RoutePaths.EXPORT_REPORTS_ADMIN) {
                window.location.reload();
            } else {
                this.setState({
                    redirect: RoutePaths.EXPORT_REPORTS_ADMIN,
                });
            }
        } else if (this.props.path !== RoutePaths.CHALLENGE) {
            this.setState({
                redirect: RoutePaths.CHALLENGE,
            });
        } else if (this.props.onSamePageClickHandler) {
            this.props.onSamePageClickHandler();
        }
    }

}

export default Header;
