import React, { Component } from "react";

interface ITitleProps {
    text: string;
}

class Title extends Component<ITitleProps> {
    public render() {
        return (
            <div className="title-parent">
                {this.props.text}
            </div>
        );
    }
}

export default Title;
