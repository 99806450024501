import React, { Component } from "react";

interface IInputFieldProps {
  placeholder?: string;
  value?: string;
  onChange?: any;
  className?: string;
  type?: string;
  onKeyPress?: any;
  allCaps?: boolean;
  autoFocus?: boolean;
  onFocusOut?: any;
}

class InputField extends Component<IInputFieldProps> {

  private nameInput: any;

  constructor(props: IInputFieldProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount = () => {
    if (this.props.autoFocus && this.props.autoFocus === true) {
      this.nameInput.focus();
    }
  }

  public render() {
    return (
      <div className={this.props.className ? this.props.className : "input-field-parent"} >
        <input
          className={"input-field"}
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyPress}
          ref={this.inputRef}
          onBlur={this.props.onFocusOut}
        />
      </div>
    );
  }

  private inputRef = (input: any) => {
    this.nameInput = input;
  }
}

export default InputField;
