import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

interface IPastReportsHeaderProps {
    columnNames: string[];
}

class PastReportsHeader extends Component<IPastReportsHeaderProps> {
    public render() {
        return (
            <div id="past-reports-header-parent">
                <Row id="past-reports-header-row">
                    {this.renderEntries()}
                </Row>
            </div>
        );
    }

    private genericColEntry = (key: string) => {
        return (
            <Col xs={1} key={key} className="past-reports-header-col">
                <div/>
            </Col>
        );
    }

    private renderEntries = () => {
        const entries = this.props.columnNames.map(this.renderColumn);
        const extraSpaces = 12 - (Math.floor(12 / this.props.columnNames.length) * this.props.columnNames.length);
        for (let i = 0; i < extraSpaces; i++) {
            if (i % 2) {
                entries.push(this.genericColEntry(`${this.props.columnNames.length + i}`));
            } else {
                entries.unshift(this.genericColEntry(`${this.props.columnNames.length + i}`));
            }
        }
        return entries;
    }

    private renderColumn = (name: string, index: number) => {
        return (
            <Col key={index} xs={Math.floor(12 / this.props.columnNames.length)} className="past-reports-header-col">
                <div>
                    {name}
                </div>
            </Col>
        );
    }
}

export default PastReportsHeader;
