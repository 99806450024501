import React, { Component } from "react";
import { Redirect } from "react-router";
import Divider from "../components/Divider";
import NoReportsListItem from "../components/NoReportsListItem";
import PastReportsTableHeader from "../components/PastReportsTableHeader";

interface IPastReportsTableProps {
    title: string;
    groupIds: string[];
    key: number;
}

class NoReportsTable extends Component<IPastReportsTableProps, any> {
    constructor(props: IPastReportsTableProps) {
        super(props);
        this.state = {};
    }

    public render() {
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />); }
        return (
            <div className="past-reports-table-parent">
                <PastReportsTableHeader
                    date={this.props.title}
                />
                <Divider />
                {[...this.props.groupIds].reverse().map(this.renderListItem)}
            </div>
        );
    }

    private renderListItem = (groupId: string, index: number) => {
        const listEntries = [groupId];
        return (
            <NoReportsListItem
                entries={listEntries}
                key={index}
            />
        );
    }
}

export default NoReportsTable;
