import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { ButtonClassNames, ButtonTypes } from "../constants/constants";

export interface IGenericButtonProps {
    onClick: any;
    disabled: boolean;
    className: ButtonClassNames;
    type?: ButtonTypes;
    renderButtonTextFunction?: any; // Allow custom rendering of html instead of displaying the value
    value?: string;
    metaData?: any; // Will be returned via the onClick handler. Use to handle multiple buttons
}

class GenericButton extends Component<IGenericButtonProps> {
    constructor(props: IGenericButtonProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        return (
            <Button
                className={`${this.props.className} generic-button-default`}
                onClick={this.onClick}
                disabled={this.props.disabled ? this.props.disabled : false}
                type={this.props.type ? this.props.type : ButtonTypes.BUTTON}
            >
                {this.props.renderButtonTextFunction ? this.props.renderButtonTextFunction() : <p className="button-p-text"> {this.props.value} </p>}
            </Button>
        );
    }

    private onClick = async () => {
        this.props.onClick(this.props);
    }
}

export default GenericButton;
