import store from "store";
import { IUser } from "../interfaces";
import ReportService from "./ReportService";
import UsersService from "./UsersService";

const AUTH_SERVICE_STORE_KEY = "auth";
const AUTH_API_SERVICE_STORE_KEY = "auth-role";

const USER_SERVICE_STORES = "stores";
class AuthService {

    public login(userId: string): IUser {
        const user: IUser = {
            id: userId,
        };
        store.set(AUTH_SERVICE_STORE_KEY, user);

        return user;
    }

    public logout() {
        store.remove(AUTH_SERVICE_STORE_KEY);
        store.remove(USER_SERVICE_STORES);

        UsersService.setCurrentUser("");
        ReportService.clearReports();
        UsersService.clearUsers();
        store.remove(AUTH_API_SERVICE_STORE_KEY);
    }

    public getUser(): IUser | void {
        return store.get(AUTH_SERVICE_STORE_KEY, undefined);
    }
}

export default new AuthService();
