import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import store from "store";
import ButtonFooter from "../components/ButtonFooter";
import ButtonGroup from "../components/ButtonGroup";
import ExportReportDatePicker from "../components/ExportReportDatePicker";
import { IGenericButtonProps } from "../components/GenericButton";
import { ButtonClassNames, ButtonGroupColumns, ButtonGroupTitles, ExportReportsSchema,
    QuartersOptions, ReportTypes, RoutePaths, YearsOptions, Timeout } from "../constants/constants";
import Header from "../containers/Header";
import { SidebarItems } from "../containers/Sidebar";
import { IUser } from "../interfaces";
import AuthService from "../services/AuthService";

/*************************************************************

Right now, the years and quarters options do not do anything.
We are sticking with the custom reports view (reportType = ReportTypes.RECENT)

To go back to the old view, resolved the 2 TODOs in this file.

***************************************************************/

const EXPORT_REPORTS_SIDEBAR_ITEMS: SidebarItems[] = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];

interface IExportReportsStates {
    redirect?: RoutePaths;
    reportType: ReportTypes | undefined;
    // Custom options for different report types
    quarter: QuartersOptions | undefined;
    year: YearsOptions | undefined;
    fromDate: Date;
    toDate: Date;
}

class ExportReports extends Component<any, IExportReportsStates> {
    constructor(props: any) {
        super(props);

        const authUser: IUser | void = AuthService.getUser();
        if (!authUser) {
            this.state = {
                fromDate: new Date(new Date().setHours(0, 0, 0, 1)),
                quarter: undefined,
                redirect: RoutePaths.USERS,
                reportType: undefined,
                toDate: new Date(new Date().setHours(23, 59, 59, 999)),
                year: undefined,
            };
            return;
        }

        this.state = {
            fromDate: new Date(new Date().setHours(0, 0, 0, 1)),
            quarter: undefined,
            reportType: ReportTypes.RECENT, // TODO: Uncomment this line to revert to the old view.
            toDate: new Date(new Date().setHours(23, 59, 59, 999)),
            year: undefined,
        };
    }

    public render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
        }
        return (
            <div className="export-reports-parent">
                {/* HEADER */}
                <div>
                    <div id="app-header">
                        <Header
                            backFunction={this.backFunction}
                            path={RoutePaths.EXPORT_REPORTS}
                            sidebarItems={EXPORT_REPORTS_SIDEBAR_ITEMS}
                        />
                    </div>
                </div>

                <CSSTransition
                    in={true}
                    appear={true}
                    classNames={"app-transition-slide"}
                    timeout={{ enter: Timeout }}
                >

                    {/* FORM */}
                    <div id="export-reports-form-parent">

                        {/* Report Type Selector */}
                        {/* {this.renderReportTypesOptions()} TODO: Uncomment this line to revert to the old view. */}
                        {/* Render fields based on report type if a type is selected*/}
                        {this.renderCustomButtonGroups()}

                    </div>
                </CSSTransition>

                {/* FOOTER */}
                {this.renderFooter()}

            </div>
        );
    }

    public reportTypeSelectHandler = async (title: ButtonGroupTitles.TYPE_OF_REPORT, value: ReportTypes[]) => {
        await this.resetFormOptions();

        this.setState({
            reportType: value[0],
        });
    }

    public yearSelectHandler = (title: ButtonGroupTitles.YEARS, value: YearsOptions[]) => {
        this.setState({
            year: value[0],
        });
    }

    public quarterSelectHandler = (title: ButtonGroupTitles.QUARTERS, value: QuartersOptions[]) => {
        this.setState({
            quarter: value[0],
        });
    }

    public dateSelectHander = (fromDate: Date, toDate: Date) => {
        this.setState({ fromDate, toDate });
    }

    public exportButtonHandler = (buttonData: IGenericButtonProps) => {
        store.set(ExportReportsSchema.EXPORT_DATE_FROM, this.state.fromDate.toISOString());
        store.set(ExportReportsSchema.EXPORT_DATE_TO, this.state.toDate.toISOString());

        this.setState({
            redirect: RoutePaths.EXPORT_SUBMITTED,
        });
    }

    public renderExportButtonText = () => {
        return (
            <p className="button-footer-export-button-text">
                EXPORT
            </p>
        );
    }

    private backFunction = () => {
        this.setState({
            redirect: RoutePaths.SUMMARY,
        });
    }

    private renderCustomButtonGroups = () => {
        switch (this.state.reportType) {
            case ReportTypes.FISCAL_YEAR: {
                return this.renderYearsOptions();
            }
            case ReportTypes.QUARTERLY: {
                return this.renderQuartersOptions();
            }
            case ReportTypes.RECENT: {
                return this.ExportRecentReportsDatePicker();
            }
        }
    }

    private renderReportTypesOptions = () => {
        return (
            <ButtonGroup
                leftAlign={true}
                title={ButtonGroupTitles.TYPE_OF_REPORT}
                options={Object.values(ReportTypes)}
                onOptionSelectHandler={this.reportTypeSelectHandler}
                nColumns={ButtonGroupColumns.HALF}
                initialSelectedValues={this.state.reportType ? [this.state.reportType] : undefined}
            />
        );
    }

    private renderYearsOptions = () => {
        return (
            <ButtonGroup
                leftAlign={true}
                title={ButtonGroupTitles.YEARS}
                options={Object.values(YearsOptions)}
                onOptionSelectHandler={this.yearSelectHandler}
                nColumns={ButtonGroupColumns.THREE}
                initialSelectedValues={this.state.year ? [this.state.year] : undefined}
            />
        );
    }

    private renderQuartersOptions = () => {
        return (
            <ButtonGroup
                leftAlign={true}
                title={ButtonGroupTitles.QUARTERS}
                options={Object.values(QuartersOptions)}
                onOptionSelectHandler={this.quarterSelectHandler}
                nColumns={ButtonGroupColumns.THREE}
                initialSelectedValues={this.state.quarter ? [this.state.quarter] : undefined}
            />
        );
    }
    private ExportRecentReportsDatePicker = () => {
        return (
            <ExportReportDatePicker
                initialToDate={this.state.toDate}
                initialFromDate={this.state.fromDate}
                onDateChange={this.dateSelectHander}
            />
        );
    }

    private renderFooter = () => {
        let readyToExport: boolean = false;
        switch (this.state.reportType) {
            case ReportTypes.FISCAL_YEAR: {
                if (this.state.year) { readyToExport = true; }
                break;
            }
            case ReportTypes.QUARTERLY: {
                if (this.state.quarter) { readyToExport = true; }
                break;
            }
            case ReportTypes.RECENT: {
                if (this.state.fromDate && this.state.toDate) { readyToExport = true; }
                break;
            }
        }

        return readyToExport ? (
            <div id="export-reports-footer">
                <ButtonFooter
                    className={ButtonClassNames.RED}
                    onClick={this.exportButtonHandler}
                    disabled={false}
                    renderButtonTextFunction={this.renderExportButtonText}
                />
            </div>
        ) :
            (
                <div id="export-reports-footer">
                    <ButtonFooter
                        className={ButtonClassNames.RED_DISABLED}
                        onClick={this.exportButtonHandler}
                        disabled={true}
                        renderButtonTextFunction={this.renderExportButtonText}
                    />
                </div>
            );

    }

    private resetFormOptions = async () => {
        await this.setState({
            fromDate: new Date(new Date().setHours(0, 0, 0, 1)),
            quarter: undefined,
            toDate: new Date(new Date().setHours(23, 59, 59, 999)),
            year: undefined,
        });
    }
}

export default ExportReports;
