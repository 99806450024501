import dateFormat from "dateformat";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import { ButtonClassNames, DatePresetOptions } from "../constants/constants";
import WindowActiveIcon from "../icons/WindowActiveIcon.svg";
import WindowIcon from "../icons/WindowIcon.svg";
import GenericButton, { IGenericButtonProps } from "./GenericButton";

type IDateOptionsButtonClasses = {
    [x in DatePresetOptions]: ButtonClassNames;
};

interface IInputFieldProps {
    initialFromDate: Date;
    initialToDate: Date;
    onDateChange: any;
}

interface IInputFieldState {
    dateOptionsButtonClasses: IDateOptionsButtonClasses;
    fromDate: Date;
    toDate: Date;
    showFromCalendar: boolean;
    showToCalendar: boolean;
}

class InputField extends Component<IInputFieldProps, IInputFieldState> {
    constructor(props: any) {
        super(props);

        this.state = {
            dateOptionsButtonClasses: {
                custom: ButtonClassNames.NO_STYLE_UNSELECTED,
                oneMonth: ButtonClassNames.NO_STYLE_UNSELECTED,
                oneWeek: ButtonClassNames.NO_STYLE_UNSELECTED,
                today: ButtonClassNames.NO_STYLE_SELECTED,
            },
            fromDate: this.props.initialFromDate,  // Start just after midnight
            showFromCalendar: false,
            showToCalendar: false,
            toDate: this.props.initialToDate,
        };
    }

    public render() {
        return (
            <Container className="export-report-date-picker-parent">
                {/* <p className="export-report-date-picker-title"> Date Range </p>  */}
                <Row className="export-report-date-options justify-content-md-center">
                    <Col xs="3" className="export-report-date-picker-col-center-contents">
                        <GenericButton
                            value="today"
                            disabled={false}
                            onClick={this.buttonClickHandler}
                            className={this.state.dateOptionsButtonClasses.today}
                            metaData={DatePresetOptions.TODAY}
                        />
                    </Col>
                    <Col xs="3" className="export-report-date-picker-col-center-contents">
                        <GenericButton
                            value="1 week"
                            disabled={false}
                            onClick={this.buttonClickHandler}
                            className={this.state.dateOptionsButtonClasses.oneWeek}
                            metaData={DatePresetOptions.ONE_WEEK}
                        />
                    </Col>
                    <Col xs="3" className="export-report-date-picker-col-center-contents">
                        <GenericButton
                            value="1 month"
                            disabled={false}
                            onClick={this.buttonClickHandler}
                            className={this.state.dateOptionsButtonClasses.oneMonth}
                            metaData={DatePresetOptions.ONE_MONTH}
                        />
                    </Col>
                    <Col xs="3">
                        <GenericButton
                            value="custom"
                            disabled={false}
                            onClick={this.buttonClickHandler}
                            className={this.state.dateOptionsButtonClasses.custom}
                            metaData={DatePresetOptions.CUSTOM}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 10, offset: 1 }} className="export-report-date-picker-date-title">
                        FROM
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} className="export-report-date-picker-date-display">
                        <div className="export-report-date-picker-date-display-text">
                            <input type="date" value={dateFormat(this.state.fromDate, "yyyy-mm-dd")} onChange={this.onFromInputChange} />
                            {this.renderFromCalendarIcon()}
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={{ span: 10, offset: 1 }} className="export-report-date-picker-date-title">
                        TO
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} className="export-report-date-picker-date-display">
                        <div className="export-report-date-picker-date-display-text">
                            <input type="date" value={dateFormat(this.state.toDate, "yyyy-mm-dd")} onChange={this.onToInputChange} />
                            {this.renderToCalendarIcon()}
                        </div>
                    </Col>
                </Row>
                {this.renderCalendars()}

            </Container>
        );
    }

    public buttonClickHandler = (buttonData: IGenericButtonProps) => {
        // Handle selected button highlighting
        const dateOptionsButtonClasses = {
            custom: ButtonClassNames.NO_STYLE_UNSELECTED,
            oneMonth: ButtonClassNames.NO_STYLE_UNSELECTED,
            oneWeek: ButtonClassNames.NO_STYLE_UNSELECTED,
            today: ButtonClassNames.NO_STYLE_UNSELECTED,
        };
        const buttonIdentifier: DatePresetOptions = buttonData.metaData!;
        dateOptionsButtonClasses[buttonIdentifier] = ButtonClassNames.NO_STYLE_SELECTED;

        let fromDate: Date = this.state.fromDate;
        let toDate: Date = this.state.toDate;

        switch (buttonIdentifier) {
            case DatePresetOptions.TODAY:
                fromDate = new Date(new Date().setHours(0, 0, 0, 1));
                toDate = new Date(new Date().setHours(23, 59, 59, 999));
                break;
            case DatePresetOptions.ONE_MONTH:
                fromDate = new Date(new Date().setHours(0, 0, 0, 1));
                // const month: number = fromDate.getMonth();
                fromDate = new Date();
                fromDate.setMonth(fromDate.getMonth() - 1);
                toDate = new Date(new Date().setHours(23, 59, 59, 999));
                break;
            case DatePresetOptions.ONE_WEEK:
                fromDate = new Date(new Date().setHours(0, 0, 0, 1));
                fromDate.setDate(fromDate.getDate() - 7);
                toDate = new Date(new Date().setHours(23, 59, 59, 999));
                break;
        }

        this.props.onDateChange(fromDate, toDate);

        this.setState({
            dateOptionsButtonClasses,
            fromDate,
            showFromCalendar: false,
            showToCalendar: false,
            toDate,
        });
    }

    private onFromInputChange = (event: any) => {
        const UTCDate: Date = new Date(event.target.value);
        const fromDate: Date = new Date(new Date(UTCDate.getTime() - UTCDate.getTimezoneOffset() * -60000).setHours(0, 0, 0, 1));
        if (fromDate <= new Date()) {
            this.setState({
                fromDate,
                toDate: this.state.toDate >= fromDate ? this.state.toDate : fromDate,
            });
            this.props.onDateChange(fromDate, this.state.toDate);
        }
    }

    private onToInputChange = (event: any) => {
        const UTCDate: Date = new Date(event.target.value);
        const toDate: Date = new Date(new Date(UTCDate.getTime() - UTCDate.getTimezoneOffset() * -60000).setHours(23, 59, 59, 999));
        if (toDate >= this.state.fromDate) {
            this.setState({
                toDate: toDate <= new Date() ? toDate : new Date(new Date().setHours(23, 59, 59, 999)),
            });
            this.props.onDateChange(this.state.fromDate, toDate);
        }
    }

    private fromCalendarIconOnClick = () => {
        this.setState({
            showFromCalendar: !this.state.showFromCalendar,
            showToCalendar: false,
        });
    }

    private toCalendarIconOnClick = () => {
        this.setState({
            showFromCalendar: false,
            showToCalendar: !this.state.showToCalendar,
        });
    }

    private renderFromCalendarIcon = () => {
        return (
            <img
                className="export-report-date-picker-date-display-text-icon"
                onClick={this.fromCalendarIconOnClick}
                alt=""
                src={this.state.showFromCalendar ? WindowActiveIcon : WindowIcon}
            />
        );
    }

    private renderToCalendarIcon = () => {
        return (
            <img
                className="export-report-date-picker-date-display-text-icon"
                onClick={this.toCalendarIconOnClick}
                alt=""
                src={this.state.showToCalendar ? WindowActiveIcon : WindowIcon}
            />
        );
    }

    private renderCalendars = () => {

        if (this.state.showFromCalendar) {
            return (
                <div>
                        <Calendar
                            onClickDay={this.fromCalendarOnChange}
                            value={this.state.fromDate}
                            calendarType="US"
                            className="export-report-date-picker-calendar"
                            maxDate={new Date()}
                        />
                </div>
            );
        }

        if (this.state.showToCalendar) {
            return (
                <div>
                        <Calendar
                            onClickDay={this.toCalendarOnChange}
                            value={this.state.toDate}
                            calendarType="US"
                            minDate={this.state.fromDate}
                            maxDate={new Date()}
                            className="export-report-date-picker-calendar"
                        />
                </div>
            );
        }
    }

    private fromCalendarOnChange = async (date: Date) => {
        const fromDate = new Date(date.setHours(0, 0, 0, 1));
        await this.setState({
            dateOptionsButtonClasses: {
                custom: ButtonClassNames.NO_STYLE_SELECTED,
                oneMonth: ButtonClassNames.NO_STYLE_UNSELECTED,
                oneWeek: ButtonClassNames.NO_STYLE_UNSELECTED,
                today: ButtonClassNames.NO_STYLE_UNSELECTED,
            },
            fromDate,
        });
        this.props.onDateChange(this.state.fromDate, this.state.toDate);
    }

    private toCalendarOnChange = async (date: Date) => {
        const toDate = new Date(date.setHours(23, 59, 59, 999));
        await this.setState({
            dateOptionsButtonClasses: {
                custom: ButtonClassNames.NO_STYLE_SELECTED,
                oneMonth: ButtonClassNames.NO_STYLE_UNSELECTED,
                oneWeek: ButtonClassNames.NO_STYLE_UNSELECTED,
                today: ButtonClassNames.NO_STYLE_UNSELECTED,
            },
            toDate,
        });
        this.props.onDateChange(this.state.fromDate, this.state.toDate);
    }
}

export default InputField;
