import store from "store";
import { RequestMethod } from "../../constants/constants";
import BaseService from "../BaseService";

const AUTH_API_SERVICE_STORE_KEY = "auth-role";

class AuthApiService {

    public async getRole() {
        const storedResponse = store.get(AUTH_API_SERVICE_STORE_KEY);

        if (!storedResponse) {
            const response = await BaseService.sendAuthenticatedRequest(RequestMethod.POST, "/authenticate", {}, {});
            store.set(AUTH_API_SERVICE_STORE_KEY, response.body);
            return response.body;
        } else {
            return storedResponse;
        }
    }
}

export default new AuthApiService();
