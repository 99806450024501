import React, { Component } from "react";
import UserCardIcon from "../components/UserCardIcon";

import { Dropdown } from "react-bootstrap";
import UserCardDropDown from "../components/UserCardDropDown";

interface IUserCardProps {
    name: string;
    lastUsed?: string;
    imgSrc?: string;
    onClick?: any;
    selected?: boolean;
    dropdown?: boolean;
    onDeleteUser?: any;
    size?: UserCardSizes;
    isAppOnline?: boolean;
}

export enum UserCardSizes {
    NORMAL = "user-card-normal",
    SMALL = "user-card-small",
}

class UserCard extends Component<IUserCardProps> {
    public render() {

        return (
            <div className={this.props.selected && this.props.selected === true ? "user-card-selected" : "user-card"} >
                <div className="user-card-contents" onClick={this.onClick}>
                    <div className={this.props.size && this.props.size === UserCardSizes.SMALL ? "user-card-photo-small" : "user-card-photo"}>
                        <UserCardIcon id={this.props.name} />
                    </div>
                    <div className={this.props.size && this.props.size === UserCardSizes.SMALL ? "user-card-name-small" : "user-card-name"}>
                        {this.props.name}
                    </div>
                </div>
                <div className="user-card-more">
                    {this.props.dropdown && this.props.dropdown ? this.renderDropdown() : null}
                </div>
            </div>
        );
    }

    private renderDropdown = () => {
        return (
            <Dropdown alignRight={true} className="user-card-dropdown-div" >
                <Dropdown.Toggle as={UserCardDropDown} id="user-card-dropdown-div" />

                <Dropdown.Menu>
                    <Dropdown.Item eventKey="1" onClick={this.onDeleteUser}> {this.props.isAppOnline ? "Delete" : "Go Online To Delete User"}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    private onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.name);
        }
    }

    private onDeleteUser = () => {
        if (this.props.onDeleteUser) {
            this.props.onDeleteUser(this.props.name);
        }
    }
}

export default UserCard;
