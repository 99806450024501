import React, { Component } from "react";

import { ReactComponent as UserIcon } from "../images/User.svg";

const GOLDEN_RATIO_CONJUGATE = 0.618033988749895;

interface IUserCardIconProps {
    id?: string;
}

class UserCardIcon extends Component<IUserCardIconProps> {
    public render() {
        return (
            <UserIcon fill={this.props.id ? this.generateRGBVal(this.props.id) : "black"} />
        );
    }

    // Used to generate an RGB value of an account based on that account's id for that account's icon
    private generateRGBVal = (id: string): string => {
        // Essentially a hash function for the colour
        const key: number = ((id.charCodeAt(0) + id.charCodeAt(1) + id.charCodeAt(2) - 65 * 2) / (28 * 2 + 10) + GOLDEN_RATIO_CONJUGATE) % 1;
        const rgb = this.HSVtoRGB(key, 0.99, 0.75);

        return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    }

    // Supporter function to convert HSV to RGB
    private HSVtoRGB = (h: number, s: number, v: number) => {
        let r;
        let g;
        let b;
        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0: r = v; g = t; b = p; break;
            case 1: r = q; g = v; b = p; break;
            case 2: r = p; g = v; b = t; break;
            case 3: r = p; g = q; b = v; break;
            case 4: r = t; g = p; b = v; break;
            default: r = v; g = p; b = q; break;
        }
        return {
            b: Math.round(b * 255),
            g: Math.round(g * 255),
            r: Math.round(r * 255),
        };
    }
}

export default UserCardIcon;
