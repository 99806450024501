import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import ButtonFooter from "../components/ButtonFooter";
import PastReportsHeader from "../components/PastReportsHeader";
import SummaryNavBar from "../components/SummaryNavBar";
import { ButtonClassNames, RoutePaths, SummaryNavbarItems, Timeout } from "../constants/constants";
import FilterUsersDropdown from "../containers/FilterUsersDropdown";
import Header from "../containers/Header";
import PastReportsTable from "../containers/PastReportsTable";
import { SidebarItems } from "../containers/Sidebar";
import { IReport, IUser } from "../interfaces";
import AuthService from "../services/AuthService";
import ReportService from "../services/ReportService";

const PAST_REPORTS_SIDEBAR_ITEMS: SidebarItems[] = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];
const PAST_REPORTS_NAVBAR_ITEMS: SummaryNavbarItems[] = [SummaryNavbarItems.SUMMARY, SummaryNavbarItems.SUBMITTED_REPORTS];

interface IReportGroup {
    date: Date;
    reports: IReport[];
}

interface IReportStates {
    allReports: IReport[];
    reportGroups: IReportGroup[];
    redirect?: RoutePaths;
    userFilterId?: string;
    isAppOnline: boolean;
    isLoading: boolean;
}

const COLUMN_NAMES: string[] = ["USER", "REPORT #", "CHALLENGE", "SERVED"];

class PastReports extends Component<any, IReportStates> {
    constructor(props: any) {
        super(props);

        window.ononline = this.onOnline;
        window.onoffline = this.onOffLine;

        const authUser: IUser | void = AuthService.getUser();
        if (!authUser) {
            this.state = {
                allReports: [],
                isAppOnline: navigator.onLine,
                isLoading: false,
                redirect: RoutePaths.USERS,
                reportGroups: [],
            };
            return;
        }

        const localReports: IReport[] = ReportService.getLocalReports();

        this.state = {
            allReports: localReports,
            isAppOnline: navigator.onLine,
            isLoading: true,
            reportGroups: this.getReportGroups(localReports),
        };
    }

    public componentDidMount = async () => {
        const user: IUser | void = AuthService.getUser();
        if (!user) {
            this.setState({
                redirect: RoutePaths.LOGIN,
            });
            return;
        }

        if (navigator.onLine) {
            const reports: IReport[] = await ReportService.getAllReports();
            const reportGroups: IReportGroup[] = this.getReportGroups(reports);

            this.setState({
                allReports: reports,
                isLoading: false,
                reportGroups,
            });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }
    public render() {
        const Navigator: any = navigator;
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
        } else {
            return (
                <div>
                    <div id="app-header">
                        <Header
                            backFunction={this.backFunction}
                            path={RoutePaths.PAST_REPORTS}
                            sidebarItems={PAST_REPORTS_SIDEBAR_ITEMS}
                        />
                    </div>
                    <div id="past-reports-sticky-container">
                        <div id="past-reports-contents">
                            <SummaryNavBar
                                selected={SummaryNavbarItems.SUBMITTED_REPORTS}
                                items={PAST_REPORTS_NAVBAR_ITEMS}
                                onClickHandler={this.onNavbarClick}
                            />
                            <FilterUsersDropdown onSelectUser={this.onFilterUser} isLoading={this.state.isLoading} online={navigator.onLine} />
                            <CSSTransition
                                in={true}
                                appear={true}
                                classNames={"app-transition-slide"}
                                timeout={{ enter: Timeout }}
                                id="app-transition-slide"
                            >
                                <div>
                                    <div id="past-reports-sticky-div">
                                        <PastReportsHeader columnNames={COLUMN_NAMES} />
                                    </div>
                                    {[...this.state.reportGroups].reverse().map(this.renderTable)}
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                    <div id="past-reports-footer">
                        <ButtonFooter
                            onClick={this.onExport}
                            disabled={navigator.onLine === false || (Navigator.standalone === true)}
                            className={navigator.onLine && !((Navigator.standalone === true)) ? ButtonClassNames.RED : ButtonClassNames.RED_DISABLED}
                            renderButtonTextFunction={this.renderFooterButton}
                        />
                    </div>
                </div>
            );
        }
    }

    private renderFooterButton = () => {
        const Navigator: any = navigator;
        const exportText: string = Navigator.standalone === true ? "Export in Browser Only" : navigator.onLine === false ? "Go Online to Texpot" : "EXPORT";
        return (
            <div id="summary-footer-button-contents">
                <div>
                    {exportText}
                </div>
            </div>
        );
    }

    private onExport = () => {
        this.setState({
            redirect: RoutePaths.EXPORT_REPORTS,
        });
    }

    private getReportGroups = (reports: IReport[]): IReportGroup[] => {
        const reportGroups: IReportGroup[] = [];

        const singleReportGroup: IReportGroup = {
            date: new Date(),
            reports: [],
        };

        reports.forEach((report: IReport) => {
            const reportDate: Date = new Date(report.timeSubmitted);
            if (singleReportGroup.reports.length === 0) {
                singleReportGroup.date = reportDate;
            }
            if (reportDate.getFullYear() === singleReportGroup.date.getFullYear() &&
                reportDate.getMonth() === singleReportGroup.date.getMonth() &&
                reportDate.getDate() === singleReportGroup.date.getDate()) {
                singleReportGroup.reports.push(report);
            } else {
                reportGroups.push({
                    date: singleReportGroup.date,
                    reports: [...singleReportGroup.reports],
                });
                singleReportGroup.reports = [report];
                singleReportGroup.date = reportDate;
            }
        });
        reportGroups.push({
            date: singleReportGroup.date,
            reports: [...singleReportGroup.reports],
        });
        return reportGroups;
    }

    private renderTable = (reportGroup: IReportGroup, index: number) => {
        return (
            <PastReportsTable
                key={index}
                date={reportGroup.date}
                reports={reportGroup.reports}
            />
        );
    }

    private backFunction = () => {
        this.setState({
            redirect: RoutePaths.CHALLENGE,
        });
    }

    private onNavbarClick = (item: SummaryNavbarItems) => {
        if (item === SummaryNavbarItems.SUMMARY) {
            this.setState({
                redirect: RoutePaths.SUMMARY,
            });
        }
    }

    private onFilterUser = (id: string | undefined) => {
        let reports: IReport[] = this.state.allReports;
        if (id) {
            reports = reports.filter((report: IReport) => report.userId === id);
        }
        this.setState({
            reportGroups: this.getReportGroups(reports),
        });
    }

    private onOnline = async (e: any) => {
        await ReportService.sendSavedReports();
        const reports: IReport[] = ReportService.getLocalReports();
        const reportGroups: IReportGroup[] = this.getReportGroups(reports);

        this.setState({
            allReports: reports,
            isAppOnline: navigator.onLine,
            reportGroups,
        });
    }

    private onOffLine = (e: any) => {

        const reports: IReport[] = ReportService.getLocalReports();
        const reportGroups: IReportGroup[] = this.getReportGroups(reports);

        this.setState({
            allReports: reports,
            isAppOnline: navigator.onLine,
            reportGroups,
        });
    }
}

export default PastReports;
