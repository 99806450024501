import AuthService from "../services/AuthService";
import UsersService from "../services/UsersService";
import ButtonFooter from "../components/ButtonFooter";
import ExportReportDatePicker from "../components/ExportReportDatePicker";
import Header from "../containers/Header";
import PastReportsHeader from "../components/PastReportsHeader";
import PastReportsTable from "../containers/PastReportsTable";
import NoReportsTable from "../containers/NoReportsTable";
import ReportContainer from "../containers/ReportContainerView";
import Select from "react-select";
import store from "store";
import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import { IGenericButtonProps } from "../components/GenericButton";
import { ButtonClassNames, ExportReportsSchema, RoutePaths, Timeout } from "../constants/constants";
import { SidebarItems } from "../containers/Sidebar";
import { IReport, IUser } from "../interfaces";
import ReportService from "../services/ReportService";

/*************************************************************

Right now, the years and quarters options do not do anything.
We are sticking with the custom reports view (reportType = ReportTypes.RECENT)

To go back to the old view, resolved the 2 TODOs in this file.

***************************************************************/

const EXPORT_REPORTS_SIDEBAR_ITEMS: SidebarItems[] = [];

interface IReportGroup {
    date: Date;
    reports: IReport[];
}

interface IExportReportsStates {
    redirect?: RoutePaths;
    reportGroups: IReportGroup[];
    fromDate: Date;
    selectedStores: any[];
    selectedStoresValues: any[];
    stores: any[];
    toDate: Date;
    reportSelected: IReport | null;
    reportSelectedStore: string | null;
}

const COLUMN_NAMES: string[] = ["STORE", "REPORT #", "CHALLENGE", "SERVED"];

class ExportReports extends Component<any, IExportReportsStates> {
    constructor(props: any) {
        super(props);

        this.state = {
            fromDate: new Date(new Date().setHours(0, 0, 0, 1)),
            redirect: undefined,
            reportGroups: [],
            reportSelected: null,
            reportSelectedStore: null,
            selectedStores: [],
            selectedStoresValues: [],
            stores: [],
            toDate: new Date(new Date().setHours(23, 59, 59, 999)),
        };
    }

    public componentDidMount = async () => {
        const user: IUser | void = AuthService.getUser();
        if (!user) {
            await this.setState({
                redirect: RoutePaths.USERS,
            });
            return;
        }

        const stores = await UsersService.setRoles();
        if (stores.length) {
            this.setState({
                stores,
            });
        } else {
            this.setState({
                redirect: RoutePaths.USERS,
            });
        }

    }

    public componentDidUpdate = async (prevProps: any, prevState: IExportReportsStates) => {
        if ((prevState.selectedStores !== this.state.selectedStores || this.state.fromDate !== prevState.fromDate || this.state.toDate !== prevState.toDate)
            && this.state.selectedStores.length !== 0) {
            const storesSelected = this.state.selectedStores[0].value === "-1" ? this.state.stores : this.state.selectedStores.map((location: any) => location.value);
            const reports = await ReportService.getAllReportsAdmin(storesSelected,
                                                                                this.state.fromDate,
                                                                                this.state.toDate);
            const reportGroups: IReportGroup[] = this.getReportGroups(reports);
            this.setState({
                reportGroups,
            });
        }
    }

    public render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
        }
        return (
            <div className="export-reports-parent">
                {/* HEADER */}
                <div>
                    <div id="app-header">
                        <Header
                            path={RoutePaths.EXPORT_REPORTS_ADMIN}
                            sidebarItems={EXPORT_REPORTS_SIDEBAR_ITEMS}
                            sidebarHideUsers={true}
                        />
                    </div>
                </div>

                <CSSTransition
                    in={true}
                    appear={true}
                    classNames={"app-transition-slide"}
                    timeout={{ enter: Timeout }}
                >

                    {/* FORM */}
                    <div id="export-reports-form-parent">
                        <p style={{ textAlign: "center" }}> Export Date Range </p>

                        {this.renderStoresDropdown()}

                        {this.renderExportRecentReportsDatePicker()}

                    </div>
                </CSSTransition>

                {/* Displayed Reports */}
                {this.renderPastReports()}

                {/* FOOTER */}
                {this.renderFooter()}

            </div>
        );
    }

    public dateSelectHander = (fromDate: Date, toDate: Date) => {
        this.setState({ fromDate, toDate });
    }

    public exportButtonHandler = (buttonData: IGenericButtonProps) => {
        store.set(ExportReportsSchema.EXPORT_DATE_FROM, this.state.fromDate.toISOString());
        store.set(ExportReportsSchema.EXPORT_DATE_TO, this.state.toDate.toISOString());
        store.set(ExportReportsSchema.EXPORT_STORE_IDS, this.state.selectedStoresValues);

        this.setState({
            redirect: RoutePaths.EXPORT_SUBMITTED_ADMIN,
        });
    }

    public renderExportButtonText = () => {
        return (
            <p className="button-footer-export-button-text">
                EXPORT
            </p>
        );
    }

    public onHandleChange = (selectedStores: any[]) => {
        let selectedStoresValues: any[] = [];

        if (!selectedStores) {
            selectedStores = [];
        }

        if (selectedStores.filter((d) => d.value === "-1").length > 0) {
            selectedStores = [{ value: "-1", label: "Select All"}];
        }

        selectedStoresValues = selectedStores.map((storeSelected: any) => {
            return storeSelected.value;
        });

        this.setState({ selectedStores, selectedStoresValues });
    }

    private renderStoresDropdown = () => {
        const { selectedStores } = this.state;
        return (
            <Select
                value={selectedStores}
                onChange={this.onHandleChange}
                options={this.getOptions()}
                className="select-store-dropdown"
                isMulti={true}
            />
        );
    }

    private getOptions = (): any[] => {
        let storesMap: any[] = [];
        if (this.state.stores.length) {
            storesMap = this.state.stores.map((storeId: string) => {
                return { value: storeId, label: storeId };
            });

            storesMap.unshift({ value: "-1", label: "Select All" });
        }
        return storesMap;
    }

    private renderExportRecentReportsDatePicker = () => {
        return (
            <ExportReportDatePicker
                initialToDate={this.state.toDate}
                initialFromDate={this.state.fromDate}
                onDateChange={this.dateSelectHander}
            />
        );
    }

    private renderFooter = () => {
        const readyToExport: boolean = (this.state.selectedStores.length > 0);

        return (
            <div id="export-reports-footer">
                <ButtonFooter
                    className={readyToExport ? ButtonClassNames.RED : ButtonClassNames.RED_DISABLED}
                    onClick={this.exportButtonHandler}
                    disabled={!readyToExport}
                    renderButtonTextFunction={this.renderExportButtonText}
                />
            </div>
        );

    }

    private closeReportView = () => {
        this.setState({
            reportSelected: null,
            reportSelectedStore: null,
        });
    }

    private renderPastReports = () => {
        if (this.state.reportSelected !== null) {
            return (<ReportContainer store={this.state.reportSelectedStore} report={this.state.reportSelected} exit={this.closeReportView} />);
        } else if (this.state.selectedStores && this.state.selectedStores.length > 0) {
            return (
                <CSSTransition
                    in={true}
                    appear={true}
                    classNames={"app-transition-slide"}
                    timeout={{ enter: Timeout }}
                    id="app-transition-slide"
                >
                    <div>
                        <div id="past-reports-sticky-div">
                            <PastReportsHeader columnNames={COLUMN_NAMES} />
                        </div>
                        {[...this.state.reportGroups].reverse().map(this.renderTable)}
                    </div>
                </CSSTransition>
            );
        }
        return null;
    }

    private selectReport = (report: IReport) => {
        this.setState({
            reportSelected: report,
            reportSelectedStore: report.storeId || "",
        });
    }

    private renderTable = (reportGroup: IReportGroup, index: number) => {
        if ((reportGroup.date).toString() === "Invalid Date") {
            return (
                <NoReportsTable
                    key={index}
                    title={"No Reports Submitted"}
                    groupIds={reportGroup.reports.map((report: IReport) => report.storeId || "")}
                />
            );
        }
        return (
            <PastReportsTable
                key={index}
                date={reportGroup.date}
                reports={reportGroup.reports}
                clickHandler={this.selectReport}
                showStores={true}
            />
        );
    }

    private getReportGroups = (reports: IReport[]): IReportGroup[] => {
        const reportGroups: IReportGroup[] = [];

        const singleReportGroup: IReportGroup = {
            date: new Date(),
            reports: [],
        };

        reports.forEach((report: IReport, index: number) => {
            const reportDate: Date = new Date(report.timeSubmitted);
            if (singleReportGroup.reports.length === 0) {
                singleReportGroup.date = reportDate;
            }
            if ((reportDate.getFullYear() === singleReportGroup.date.getFullYear() &&
                reportDate.getMonth() === singleReportGroup.date.getMonth() &&
                reportDate.getDate() === singleReportGroup.date.getDate()) ||
                (((reportDate).toString() === "Invalid Date") && (singleReportGroup.date).toString() === "Invalid Date")) {
                singleReportGroup.reports.push(report);
            } else {
                if ((singleReportGroup.date).toString() === "Invalid Date") {
                    reportGroups.unshift({
                        date: singleReportGroup.date,
                        reports: [...singleReportGroup.reports],
                    });
                } else {
                    reportGroups.push({
                        date: singleReportGroup.date,
                        reports: [...singleReportGroup.reports],
                    });
                }
                singleReportGroup.reports = [report];
                singleReportGroup.date = reportDate;
            }
        });
        if ((singleReportGroup.date).toString() === "Invalid Date") {
            reportGroups.unshift({
                date: singleReportGroup.date,
                reports: [...singleReportGroup.reports],
            });
        } else {
            reportGroups.push({
                date: singleReportGroup.date,
                reports: [...singleReportGroup.reports],
            });
        }

        return reportGroups;
    }
}

export default ExportReports;
