import { RequestMethod } from "../../constants/constants";
import { IUser } from "../../interfaces";
import BaseService from "../BaseService";

class UsersApiService {

    public setUserRoles = async (): Promise<any> => {
        const rolesResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.POST, `/authenticate/`);
        return rolesResponse.body;
    }

    public createUser = async (user: IUser): Promise<IUser> => {
        const userBody = { userInitials: user.id };
        const reportsResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.POST, `/user`, userBody);
        return { id: reportsResponse.body.userInitials };
    }

    public getUsers = async (): Promise<IUser[]> => {
        const usersResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.GET, `/user/list`);

        return usersResponse.body.map((user: any) => {
            return {
                id: user.userInitials,
            };
        });
    }

    public deleteUser = async (userId: string): Promise<any> => {
        const body = { userInitials: userId };
        const usersResponse = await BaseService.sendAuthenticatedRequest(RequestMethod.DELETE, `/user/`, body);
        return usersResponse;
    }
}

export default new UsersApiService();
