import React, { Component } from "react";
import ButtonGroup from "../components/ButtonGroup";
import { ButtonGroupColumns, ButtonGroupTitles } from "../constants/constants";
import { IsCustomerServedEnum, IsCustomerServed } from "../interfaces";

const IS_CUSTOMER_SERVED_OPTIONS = Object.values(IsCustomerServedEnum);

interface IIsCustomerServedOptions {
    onOptionsSelected: any;
    initialValue?: IsCustomerServedEnum;
    disabled?: boolean;
}

class IsCustomerServedOptions extends Component<IIsCustomerServedOptions> {

    public render() {
        return (
            <div id="is-customer-served-options-parent">
                <ButtonGroup
                    title={ButtonGroupTitles.IS_CUSTOMER_SERVED}
                    options={IS_CUSTOMER_SERVED_OPTIONS}
                    onOptionSelectHandler={this.onSelected}
                    nColumns={ButtonGroupColumns.ONE}
                    initialSelectedValues={this.props.initialValue ? [this.props.initialValue] : undefined}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    buttonWidth={"144px"}
                    optionMapper={IsCustomerServed}
                />
            </div>
        );
    }

    private onSelected = (title: ButtonGroupTitles, value: IsCustomerServedEnum[]) => {
        this.props.onOptionsSelected(title, value[0]);
    }
}

export default IsCustomerServedOptions;
