import React, { Component } from "react";

export enum SummaryNavBarTypes {
    SMALL = "summary-navbar-parent-small",
    NORMAL = "summary-navbar-parent",
}

interface ISummaryNavBarProps {
    selected: string;
    items: string[];
    onClickHandler: any;
    type?: SummaryNavBarTypes;
}

class SummaryNavBar extends Component<ISummaryNavBarProps> {
    public render() {
        return (
            <div id={this.props.type || "summary-navbar-parent"}>
                {this.props.items.map(this.renderItem)}
            </div>
        );
    }

    private renderItem = (item: string, index: number) => {
        return (
            <div key={index} className={this.props.type && this.props.type === SummaryNavBarTypes.SMALL ? "summary-navbar-item-wrapper" : undefined}>
                <div
                    className={item === this.props.selected ? "summary-navbar-item-selected" : "summary-navbar-item"}
                    data-tag={index}
                    key={index}
                    onClick={item === this.props.selected ? undefined : this.onClick}
                >
                    {item}
                </div>
            </div>
        );
    }

    private onClick = (e: any) => {
        this.props.onClickHandler(this.props.items[e.target.getAttribute("data-tag")]);
    }

}

export default SummaryNavBar;
