import React, { Component } from "react";

interface IFilterUsersDropdownButtonProps {
    onClick: any;
    children: any;
}

class FilterUsersDropdownButton extends Component<IFilterUsersDropdownButtonProps> {
    public render() {
        return (
            <div className="filter-users-drop-down-button-parent" onClick={this.handleClick}>
                {this.props.children}
            </div>
        );
    }

    private handleClick = (e: any) => {
        e.preventDefault();

        this.props.onClick(e);
    }
}

export default FilterUsersDropdownButton;
