import React, { Component } from "react";
import { Redirect } from "react-router";
import { CSSTransition } from "react-transition-group";
import store from "store";
import ButtonFooter from "../components/ButtonFooter";
import GenericButton from "../components/GenericButton";
import Title from "../components/Title";
import { ButtonClassNames, ExportReportsSchema, RoutePaths, Timeout } from "../constants/constants";
import Header from "../containers/Header";

import { SidebarItems } from "../containers/Sidebar";
import FileExportedIcon from "../images/FileExportedIcon.svg";
import { IUser } from "../interfaces";
import AuthService from "../services/AuthService";
import ReportsService from "../services/ReportService";

const SUBMITTED_SIDEBAR_ITEMS: SidebarItems[] = [SidebarItems.NEW_REPORT, SidebarItems.ACTIVITY_SUMMARY, SidebarItems.PAST_REPORTS];

interface ISubmittedStates {
    redirectPath?: string;
    csvData: string;
    dateFrom: string;
    dateTo: string;
    emailSent: boolean;
    loading: boolean;
    standalone: boolean; // Use to detect if you are on a PWA or not. downloading files is not currently well supported on PWAs, so we disable it.
    showDownloadDisabledText: boolean;
}

class ExportSubmitted extends Component<any, ISubmittedStates> {
    constructor(props: any) {
        super(props);
        const authUser: IUser | void = AuthService.getUser();

        if (!authUser) {
            this.state = {
                csvData: "",
                dateFrom: "",
                dateTo: "",
                emailSent: false,
                loading: false,
                redirectPath: RoutePaths.USERS,
                showDownloadDisabledText: false,
                standalone: false,
            };
            return;
        }

        const Navigator: any = navigator;

        this.state = {
            csvData: "",
            dateFrom: store.get(ExportReportsSchema.EXPORT_DATE_FROM, ""),
            dateTo: store.get(ExportReportsSchema.EXPORT_DATE_TO, ""),
            emailSent: false,
            loading: false,
            showDownloadDisabledText: false,
            standalone: Navigator.standalone,
        };
    }

    public componentDidMount = async () => {
        if (!this.state.csvData.length) {
            const csvData = await ReportsService.getReportsCSV(this.state.dateFrom, this.state.dateTo);

            this.setState({
                csvData,
            });
        }
    }

    public render() {
        if (this.state.redirectPath) {
            return (<Redirect to={this.state.redirectPath} />);
        } else {
            return (
                <div id="export-submitted-parent">
                    <div id="app-header">
                        <Header
                            path={RoutePaths.EXPORT_SUBMITTED}
                            sidebarItems={SUBMITTED_SIDEBAR_ITEMS}
                        />
                    </div>
                    <div id="app-header-filler" />
                    <CSSTransition
                        in={true}
                        appear={true}
                        classNames={"app-transition-slide"}
                        timeout={{ enter: Timeout }}
                    >
                        <div id="submitted-parent">
                            <div id="submitted-contents-wrapper">
                                <div id="export-submitted-contents">
                                    <div id="submitted-image-parent">
                                        <img id="submitted-image" src={FileExportedIcon} alt="" />
                                    </div>
                                    <Title text={"File Ready!"} />
                                    {/* <div className="export-submitted-view-button"><a href="#0">VIEW</a></div> */}
                                    <div id="submitted-buttons">
                                        <div className="submitted-button">
                                            <GenericButton
                                                onClick={this.onDownloadButtonSubmitted}
                                                disabled={this.state.loading}
                                                value={"SAVE ON DEVICE"}
                                                className={ButtonClassNames.SELECTED}
                                            />
                                        </div>
                                        <p className="submitted-sent-text"> {this.state.showDownloadDisabledText ? "Open app in a browser to save on device" : null} </p>
                                        <div> {this.state.emailSent ? <p className="submitted-sent-text"> Sent! </p> : null} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    {/* Done button */}
                    {this.renderFooter()}
                </div>
            );
        }
    }

    public doneButtonHandler = () => {
        this.setState({
            redirectPath: RoutePaths.EXPORT_REPORTS,
        });
    }

    public renderExportButtonText = () => {
        return (
            <p className="button-footer-export-button-text"> DONE </p>
        );
    }

    private onDownloadButtonSubmitted = async () => {
        const fileContents = `data:text/csv;charset=utf-8,${this.state.csvData}`;
        const encodedUri = encodeURI(fileContents);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", `report-${this.state.dateFrom}-${this.state.dateTo}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    private pwaOnDownloadButtonSubmitted = async () => {

        this.setState({
            showDownloadDisabledText: true,
        });
    }

    private onEmailButtonSubmitted = async () => {

        this.setState({
            loading: true,
        });

        const emailResonse = await ReportsService.sendCSVDataEmail(this.state.dateFrom, this.state.dateTo);

        this.setState({
            emailSent: emailResonse,
            loading: false,
        });
    }

    private renderFooter = () => {
        return (
            <div id="export-submitted-footer">
                <ButtonFooter
                    className={this.state.loading ? ButtonClassNames.RED_DISABLED : ButtonClassNames.RED}
                    onClick={this.doneButtonHandler}
                    disabled={this.state.loading}
                    renderButtonTextFunction={this.renderExportButtonText}
                />
            </div>
        );
    }

}

export default ExportSubmitted;

// <div className="submitted-button">
// <GenericButton
//     onClick={this.onEmailButtonSubmitted}
//     disabled={this.state.loading}
//     value={"EMAIL REPORT"}
//     className={ButtonClassNames.SELECTED}
// />
// </div>
