import React, { Component } from "react";
import { ButtonClassNames } from "../constants/constants";
import GenericButton from "./GenericButton";

interface ISubmittedReportsFooterProps {
    onClick: any;
    disabled: boolean;
    className: ButtonClassNames;
    renderButtonTextFunction: any;
}

class SubmittedReportsFooter extends Component<ISubmittedReportsFooterProps, any> {
    constructor(props: ISubmittedReportsFooterProps) {
        super(props);

        this.state = {
        };
    }

    public onClick = async () => {
        // await this.setState({
        //     selectedValue: buttonData.value,
        // });

        // this.props.onOptionSelectHandler(this.props.title, this.state.selectedValue);
    }

    public render() {
        return (
            <div id="button-footer-parent">
                <div className="button-footer-export-button">
                    <GenericButton
                        className={this.props.className}
                        onClick={this.props.onClick}
                        disabled={this.props.disabled}
                        renderButtonTextFunction={this.props.renderButtonTextFunction}
                    />
                </div>
            </div>
        );
    }
}

export default SubmittedReportsFooter;
