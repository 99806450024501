import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import { RoutePaths } from "../constants/constants";
import history from "./../helpers/history";
import Challenge from "./Challenge";
import ExportReports from "./ExportReports";
import ExportReportsAdmin from "./ExportReportsAdmin";
import ExportSubmitted from "./ExportSubmitted";
import ExportSubmittedAdmin from "./ExportSubmittedAdmin";
import Login from "./Login";
import PastReports from "./PastReports";
import SelectUsers from "./SelectUsers";
import Submitted from "./Submitted";
import Summary from "./Summary";
import ViewReport from "./ViewReport";

import ReportService from "../services/ReportService";

// Highest level parent container for all views, containers and components

// updates service worker when user navigates to a new route very important for PWA experience when building
// with react

history.listen(() => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .getRegistration()
      .then((reg) => {

        if (!reg) {
          return;
        }

        reg.update();
      });
  }
});

class App extends Component<any> {
  constructor(props: any) {
    super(props);
    window.ononline = this.onNetworkChangeHandler;
  }
  public render() {

    return (
      <div className="App">
        <Router history={history}>
          <Route
            exact={true}
            path={RoutePaths.LOGIN}
            component={Login}
          />
          <Route
            exact={true}
            path={RoutePaths.CHALLENGE}
            component={Challenge}
          />
          <Route
            exact={true}
            path={RoutePaths.SUBMITTED}
            component={Submitted}
          />
          <Route
            exact={true}
            path={RoutePaths.PAST_REPORTS}
            component={PastReports}
          />
          <Route
            exact={true}
            path={RoutePaths.EXPORT_REPORTS}
            component={ExportReports}
          />
          <Route
            exact={true}
            path={RoutePaths.EXPORT_SUBMITTED}
            component={ExportSubmitted}
          />
          <Route
            path={RoutePaths.USERS}
            component={SelectUsers}
          />
          <Route
            path={`${RoutePaths.VIEW_REPORT}/:id`}
            component={ViewReport}
          />
          <Route
            exact={true}
            path={RoutePaths.SUMMARY}
            component={Summary}
          />
          <Route
            exact={true}
            path={RoutePaths.EXPORT_REPORTS_ADMIN}
            component={ExportReportsAdmin}
          />
          <Route
            exact={true}
            path={RoutePaths.EXPORT_SUBMITTED_ADMIN}
            component={ExportSubmittedAdmin}
          />
        </Router>
      </div>
    );
  }

  private onNetworkChangeHandler(e: any) {
    ReportService.sendSavedReports();
  }

}

export default App;
