import React, { Component } from "react";

interface ISidebarItemProps {
    imgSrc: string;
    text: string;
    onClick?: any;
}

class SidebarItem extends Component<ISidebarItemProps> {
    public render() {
        return (
            <div onClick={this.props.onClick} className="sidebar-item-parent">
                <div className="sidebar-item-image-parent">
                        <img src={this.props.imgSrc} alt="" />
                </div>
                <div className="sidebar-item-text-parent">
                    <div className="sidebar-item-text">
                        {this.props.text}
                    </div>
                </div>
            </div>
        );
    }
}

export default SidebarItem;
