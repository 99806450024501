import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonClassNames, ButtonGroupColumns, ButtonGroupTitles, ButtonTypes } from "./../constants/constants";
import GenericButton, { IGenericButtonProps } from "./GenericButton";

interface IButtonGroupProps {
    title: ButtonGroupTitles;
    options: string[];
    nColumns: ButtonGroupColumns;
    onOptionSelectHandler: any;
    initialSelectedValues?: string[];
    leftAlign?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    buttonWidth?: string;
    optionMapper?: any;
}

interface IButtonGroupState {
    selectedValues: string[];
}

class ButtonGroup extends Component<IButtonGroupProps, IButtonGroupState> {
    constructor(props: IButtonGroupProps) {
        super(props);

        this.state = {
            selectedValues: this.props.initialSelectedValues || [],
        };
    }

    public onClick = async (buttonData: IGenericButtonProps) => {
        if (buttonData.value) {
            let selectedValues: string[] = this.state.selectedValues;

            if (this.props.multiple && this.props.multiple === true) {
                if (selectedValues.some((selectedValue: string) => selectedValue === buttonData.value)) {
                    selectedValues = selectedValues.filter((selectedValue: string) => selectedValue !== buttonData.value);
                } else {
                    selectedValues.push(buttonData.value);
                }
            } else {
                selectedValues = [buttonData.value];
            }

            this.setState({
                selectedValues,
            });

            this.props.onOptionSelectHandler(this.props.title, selectedValues);
        }
    }

    public componentDidUpdate(prevProps: IButtonGroupProps) {
        if (prevProps.initialSelectedValues !== this.props.initialSelectedValues) {
            this.setState({
                selectedValues: this.props.initialSelectedValues ? this.props.initialSelectedValues : [],
            });
        }
    }

    public render() {
        return (
            <Container className="buttongroup-container">
                <p className={this.props.leftAlign ? "buttongroup-title-left" : "buttongroup-title"} > {this.props.title} </p>
                <Container style={{ width: this.props.buttonWidth }}>
                    <Row >
                        {this.renderButtons()}
                    </Row>
                </Container>
            </Container >
        );
    }

    private renderButtons() {
        return this.props.options.map((option: string, index: number) => {

            switch (this.props.nColumns) {
                case ButtonGroupColumns.ONE:
                    return this.renderButtonsSingleCol(option, index);
                case ButtonGroupColumns.TWO:
                    return this.renderButtonsDoubleCol(option, index);
                case ButtonGroupColumns.THREE:
                    return this.renderButtonsTripleCol(option, index);
                case ButtonGroupColumns.HALF:
                    return this.renderButtonsHalfCol(option, index);
            }
            return "Invalid nColumns Value. Please check ButtonGroupColumns.";
        });
    }

    private renderButtonsDoubleCol(option: string, index: number) {
        return (
            <Col xs="6" className="buttongroup-col-buttons" key={index}>
                <GenericButton
                    onClick={this.onClick}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    value={option}
                    type={ButtonTypes.SUBMIT}
                    className={this.state.selectedValues.includes(option) ? ButtonClassNames.SELECTED : ButtonClassNames.UNSELECTED}
                    key={index}
                    renderButtonTextFunction={this.renderExportButtonText(option)}
                />
            </Col>
        );
    }

    private renderButtonsSingleCol(option: string, index: number) {
        return (
            <Col xs="12" className="buttongroup-col-buttons" key={option}>
                <GenericButton
                    onClick={this.onClick}
                    disabled={this.props.disabled || false}
                    value={option}
                    type={ButtonTypes.SUBMIT}
                    className={this.state.selectedValues.includes(option) ? ButtonClassNames.SELECTED : ButtonClassNames.UNSELECTED}
                    key={index}
                    renderButtonTextFunction={this.renderExportButtonText(option)}
                />
            </Col>
        );
    }

    private renderButtonsTripleCol(option: string, index: number) {
        return (
            <Col xs="4" className="buttongroup-col-buttons" key={option}>
                <GenericButton
                    onClick={this.onClick}
                    disabled={this.props.disabled || false}
                    value={option}
                    type={ButtonTypes.SUBMIT}
                    className={this.state.selectedValues.includes(option) ? ButtonClassNames.SELECTED : ButtonClassNames.UNSELECTED}
                    key={index}
                    renderButtonTextFunction={this.renderExportButtonText(option)}
                />
            </Col>
        );
    }

    private renderButtonsHalfCol(option: string, index: number) {
        return (
            <Col xs="12" className="buttongroup-col-buttons" key={option}>
                <div className="buttongroup-half-button-div">
                    <GenericButton
                        onClick={this.onClick}
                        disabled={this.props.disabled || false}
                        value={option}
                        type={ButtonTypes.SUBMIT}
                        className={this.state.selectedValues.includes(option) ? ButtonClassNames.SELECTED : ButtonClassNames.UNSELECTED}
                        key={index}
                        renderButtonTextFunction={this.renderExportButtonText(option)}
                    />
                </div>
            </Col>
        );
    }

    // Function that returns the element that will display text for the buttons.
    private renderExportButtonText = (option: string) => () => {
        return (
            <p className="button-p-text"> {this.props.optionMapper ? this.props.optionMapper[option] || option : option} </p>
        );
    }
}

export default ButtonGroup;
