import store from "store";
import { IReport, IServiceResponse, IUser } from "../interfaces";
import UsersApiService from "./api/UsersApiService";
import ReportService from "./ReportService";

const USER_SERVICE_CURRENT_STORE_KEY = "user-current";
const USER_SERVICE_ALL_USERS = "user-all";
const USER_SERVICE_STORES = "stores";

class UserService {
    public getUsers(): IUser[] {
        return this.getAllUsers();
    }

    // public addUsers(users: IUser[]) {
    //     const existingUsers: IUser[] = this.getAllUsers();
    //     users.forEach((user: IUser) => {
    //         if (!existingUsers.some((existingUser: IUser) => user.id === existingUser.id)) {
    //             existingUsers.push(user);
    //         }
    //     });
    //     this.setAllUsers(existingUsers);
    // }

    public addUser = async (userToCreate: IUser): Promise<IServiceResponse> => {
        const existingUsers: IUser[] = this.getAllUsers();
        if (existingUsers.some((existingUser: IUser) => userToCreate.id === existingUser.id)) {
            return {
                message: "User Already Exists",
                success: false,
            };
        }
        const user = await UsersApiService.createUser(userToCreate);

        existingUsers.push(user);
        this.setAllUsers(existingUsers);
        return {
            message: "Added User",
            success: true,
        };
    }

    public deleteUser = async (id: string) => {

        await UsersApiService.deleteUser(id);
        const existingUsers: IUser[] = this.getAllUsers();
        const remainingUsers: IUser[] = existingUsers.filter((existingUser: IUser) => existingUser.id !== id);
        this.setAllUsers(remainingUsers);
    }

    public setCurrentUser(id: string) {
        store.set(USER_SERVICE_CURRENT_STORE_KEY, id);
    }

    public getCurrentUserId(): string {
        return store.get(USER_SERVICE_CURRENT_STORE_KEY, "");
    }

    public getArchivedUserIds = async (): Promise<string[]> => {
        const existingUsersArray: string[] = this.getAllUsers().reduce((accumulator: string[], user: IUser) => {
            accumulator.push(user.id);
            return accumulator;
        }, []);

        const existingUsersSet: Set<string> = new Set(existingUsersArray);
        const existingReports: IReport[] = await ReportService.getLocalReports();

        existingReports.forEach((report: IReport) => {
            existingUsersSet.add(report.userId);
        });

        return Array.from(existingUsersSet);
    }

    public setRoles = async (): Promise<any> => {
        const userData = await UsersApiService.setUserRoles();
        store.set(USER_SERVICE_STORES, userData.stores);
        return userData.stores;
    }

    public getAllUsersApi = async (): Promise<IUser[]> => {
        if (navigator.onLine) {
            const users = await UsersApiService.getUsers();
            store.set(USER_SERVICE_ALL_USERS, users);
        }
        return store.get(USER_SERVICE_ALL_USERS, []);
    }

    public clearUsers = () => {
        this.setAllUsers([]);
    }

    private getAllUsers(): IUser[] {
        return store.get(USER_SERVICE_ALL_USERS, []);
    }

    private setAllUsers(users: IUser[]) {
        return store.set(USER_SERVICE_ALL_USERS, users);
    }
}

export default new UserService();
