import * as Msal from "msal";
import AuthService from "./AuthService";

class MsalAuthService {

    private MsalAgentInstance: Msal.UserAgentApplication;

    private MsalConfig: Msal.Configuration;

    private MsalAuthenticationParameters: Msal.AuthenticationParameters;

    private accessToken: string | undefined;

    constructor() {

        if (!process.env.REACT_APP_AZURE_AD_AUTHORITY || !process.env.REACT_APP_AZURE_AD_CLIENT_SECRET) {
            throw Error("Missing Azure AD Credentials");
        }

        this.MsalConfig = {
            auth: {
                authority: process.env.REACT_APP_AZURE_AD_AUTHORITY!,
                clientId: process.env.REACT_APP_AZURE_AD_CLIENT_SECRET!,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },
        };

        this.MsalAuthenticationParameters = {
            scopes: ["User.Read"],
        };

        this.MsalAgentInstance = new Msal.UserAgentApplication(this.MsalConfig);

        this.MsalAgentInstance.handleRedirectCallback(async (authError: Msal.AuthError, authResponse: Msal.AuthResponse | undefined) => {
            // User has permissions via Azure AD and successfully logged in
            if (authResponse && authResponse.uniqueId !== "") {
                if (authResponse.accessToken) {
                    this.accessToken = authResponse.accessToken;
                }
                AuthService.login(authResponse.uniqueId);
            }
        });
    }

    public getAccessToken = () => {
        return this.accessToken;
    }

    public authenticate = () => {
        // if the user is already logged in you can acquire a token silently
        if (this.MsalAgentInstance.getAccount()) {
            this.acquireTokens();
        } else {
            // Otherwise, log the user in
            this.MsalAgentInstance.loginRedirect(this.MsalAuthenticationParameters);
        }
    }

    public unauthenticate = () => {
        this.MsalAgentInstance.logout();
        AuthService.logout();
    }

    public acquireTokens = async () => {
        try {
            const tokenResponse = await this.MsalAgentInstance.acquireTokenSilent(this.MsalAuthenticationParameters);
            this.accessToken = tokenResponse.accessToken;
        } catch (error) {
            // ID token expired
            if (error.name === "InteractionRequiredAuthError") {
                this.MsalAgentInstance.acquireTokenRedirect(this.MsalAuthenticationParameters);
            }
        }
    }
}

export default new MsalAuthService();
