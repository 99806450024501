import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./views/App";

// style framework
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

// Style Sheets
import "./sass/__index.scss";

// PWA
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    ),
    document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
