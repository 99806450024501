import React, { Component } from "react";

interface ILargeInputProps {
    onChange: any;
    hint?: string;
    value?: string;
    disabled?: boolean;
}

class LargeInput extends Component<ILargeInputProps> {
    public render() {
        return (
            <textarea
                className={"large-input"}
                onChange={this.props.onChange}
                placeholder={this.props.hint}
                value={this.props.value}
                disabled={this.props.disabled || false}
            />
        );
    }
}

export default LargeInput;
