import React, { Component } from "react";
import ButtonGroup from "../components/ButtonGroup";
import { ButtonGroupTitles } from "../constants/constants";
import { ChallengeOptionsEnum, ChallengeOptionsMapper } from "./../interfaces";

const CHALLENGE_OPTIONS: string[] = Object.values(ChallengeOptionsEnum);

interface IChallengeProps {
    onOptionsSelected: any;
    initialValue?: ChallengeOptionsEnum;
    disabled?: boolean;
}

class ChallengeOptions extends Component<IChallengeProps> {

    public render() {
        return (
            <div id="challenge-options-parent">
                <ButtonGroup
                    title={ButtonGroupTitles.CHALLENGE_OPTIONS}
                    options={CHALLENGE_OPTIONS}
                    onOptionSelectHandler={this.onOptionsSelected}
                    nColumns={1}
                    initialSelectedValues={this.props.initialValue ? [this.props.initialValue] : undefined}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    optionMapper={ChallengeOptionsMapper}
                />
            </div>
        );
    }

    private onOptionsSelected = (title: ButtonGroupTitles, value: string[]) => {
        return this.props.onOptionsSelected(title, value[0]);
    }
}

export default ChallengeOptions;
