export enum RequestMethod {
    GET = "GET",
    POST = "POST",
    DELETE = "DELETE",
}

export enum ButtonTypes {
    SUBMIT = "submit",
    BUTTON = "button",
    RESET = "reset",
}

export enum ButtonGroupTitles {
    CHALLENGE_OPTIONS = "Reason for Challenge",
    CUSTOMER_SERVING_OPTIONS = "Customers Serving",
    ID_OPTIONS = "Identification Produced",
    IS_CUSTOMER_SERVED = "Was the Customer Served?",
    TYPE_OF_REPORT = "Type of Report",
    YEARS = "Years",
    QUARTERS = "Quarters",
    EXPORT_FORMAT = "Export Format",
}

export enum ButtonClassNames {
    UNSELECTED = "button-unselected",
    SELECTED = "button-selected",
    GREEN = "button-green",
    RED = "button-red",
    RED_DISABLED = "button-red-disabled",
    NO_STYLE_UNSELECTED = "no-style-unselected",
    NO_STYLE_SELECTED = "no-style-selected",
    BLACK_DISABLE = "button-black-disable",
}

export enum ButtonGroupColumns {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    HALF = 0.5,
}

export const endPoint = `/cr`;

export enum RoutePaths {
    LOGIN = "/",
    CHALLENGE = "/challenge",
    SUBMITTED = "/submitted",
    PAST_REPORTS = "/past-reports",
    EXPORT_REPORTS = "/export-reports",
    EXPORT_SUBMITTED = "/export-submitted",
    USERS = "/users",
    VIEW_REPORT = "/report",
    SUMMARY = "/summary",
    EXPORT_REPORTS_ADMIN = "/export-admin",
    EXPORT_SUBMITTED_ADMIN = "/export-submitted-admin",
}

export const LEGAL_DRINKING_AGE: number = 19;

// EXPORT REPORTS PAGE
export enum ReportTypes {
    FISCAL_YEAR = "Year",
    QUARTERLY = "Quarterly",
    RECENT = "Recent",
}

export enum YearsOptions { // TODO: needs a better way to handle this
    ONE = "18-19",
    TWO = "17-18",
    THREE = "16-17",
}

export enum QuartersOptions {
    Q1 = "Q1",
    Q2 = "Q2",
    Q3 = "Q3",
    Q4 = "Q4",
}

// Date Picker
export enum DatePresetOptions {
    TODAY = "today",
    ONE_WEEK = "oneWeek",
    ONE_MONTH = "oneMonth",
    CUSTOM = "custom",
}

export enum SummaryNavbarItems {
    SUMMARY = "Summary",
    SUBMITTED_REPORTS = "Submitted Reports",
}

export enum ExportReportsSchema {
    EXPORT_DATE_FROM = "export.date.from",
    EXPORT_DATE_TO = "export.date.to",
    EXPORT_STORE_IDS = "export.store.ids",
}

export enum SupportDocUrls {
    CORPERATE_ADMIN_DOC = "https://lcbonext.blob.core.windows.net/cr-assets/AdminSupportDocumentation.pdf",
    STORE_ADMIN_DOC = "https://lcbonext.blob.core.windows.net/cr-assets/SupportDocumentation.pdf",
}

export const DateFormat = "mmmm d, yyyy h:MM TT";

export const Timeout = 300;
